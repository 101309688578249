import { HEIGHT_GLOBAL, HEIGHT_CONTENT, ACTIVE_ENTREGA } from "../../constants/ActionTypes";

let initialSettings = {
    heightGlobal: 0,
    heightContent: 0,
    activeEntrega: false

};

const Settings = (state = initialSettings, action) => {
    switch (action.type) {
        case HEIGHT_GLOBAL:
            return {
                ...state,
                heightGlobal: action.height,
            };
        case HEIGHT_CONTENT:
            return {
                ...state,
                heightContent: action.height
            };
        case ACTIVE_ENTREGA:
            return {
                ...state,
                activeEntrega: action.active
            };
        default:
            return state;
    }
};

export default Settings;