import React from "react";
import "./styles.scss";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const CardContainer = ({
  children,
  button,
  buttonText,
  buttonFunction,
  cardTitle,
  centerTitle,
  separationLine,
  icon,
  iconIsComponent,
  isRed,
  noBorder,
  showButton = true,
  goBackButton,
  goBackFunction,
}) => {
  return (
    <>
      <div
        className={`card-container ${
          button && showButton ? "with-button" : null
        }`}
      >
        <div
          className={`card-title ${centerTitle ? "center-title" : ""} ${
            goBackButton && "with-go-back-button"
          }`}
        >
          {icon ? (
            iconIsComponent ? (
              icon
            ) : (
              <img src={icon} alt="Title Icon" />
            )
          ) : null}
          {goBackButton && (
            <ArrowBackIosIcon className="arrow-back" onClick={() => goBackFunction()} />
          )}
          {cardTitle}
        </div>
        {separationLine && <hr></hr>}
        {children}
      </div>
      {button && showButton && (
        <div
          className={`button-container ${!noBorder && "with-button-border"}`}
          onClick={buttonFunction}
        >
          <div className={`button ${isRed && "red"}`}>{buttonText}</div>
        </div>
      )}
    </>
  );
};

export default CardContainer;
