import React, { Component } from "react";
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import "./menu.scss";

class Menu extends Component {

  /**
   *
   * @param {*} pageToRedirect
   */
  redirect = (pageToRedirect) => {
    if (pageToRedirect) {
      //
      if (pageToRedirect === 'extracts' && this.props.userData.negociemos) {
        // ALERT.
        toast.warn(this.props.userData.negociemos_msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });
      } else {
          this.props.history.push(`/auth/${pageToRedirect}`);
      }
    };
  };

  /**
   * Establece si se debe activar el tab o no
   */
  activateTab(pathPage, isLoadFirst) {
    let basePath = "/auth";
    let path = basePath + pathPage;
    const { location } = this.props;
    let pathname = location && location.pathname ? location.pathname : null;
    if (pathname === path) {
      return true;
    } else if (isLoadFirst && pathname === basePath) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <div id="content-menu">
        <div
          className={this.activateTab("/pay", true) ? "activated-btn" : ""}
          onClick={() => this.redirect("pay")}
        >
          Pagar
        </div>
        <div
          className={this.activateTab("/purchases") ? "activated-btn" : ""}
          onClick={() => this.redirect("purchases")}
        >
          Créditos
        </div>
        <div
          className={this.activateTab("/my-quota") ? "activated-btn" : ""}
          onClick={() => this.redirect("my-quota")}
        >
          Cupo
        </div>
        <div
          className={this.activateTab("/movements") ? "activated-btn" : ""}
          onClick={() => this.redirect("movements")}
        >
          Movimientos
        </div>
        <div
          className={this.activateTab("/extracts") ? "activated-btn" : ""}
          onClick={() => this.redirect("extracts")}
        >
          Documentos
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
    const { userData } = auth;
    return { userData }
};

export default connect(mapStateToProps, {})(withRouter(Menu));
