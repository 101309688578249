// Package
import React from "react";
import { useHistory } from 'react-router-dom';
import { Button, Icon } from '@material-ui/core';
import { Dropdown } from "react-bootstrap";

// Components
import MenuJamar from '../Sidebar/menuJamar/menuJamar';
import ViewsJamar from '../../pages/viewsJamar/index';
import HeaderBar from '../Sidebar/Header';

// Assets
import './mainAppJamar.scss';
import logoJamar from "../../assets/images/JamarLogo.svg"
import botonCrediJamar from "../../assets/images/boton-credijamar.svg"

// Constants
import { userSignOut } from "../../appRedux/actions/Auth";
import { useDispatch, useSelector } from "react-redux";

import axios from 'axios';
import { toast } from 'react-toastify';

require('dotenv').config();

const MainAppJamar = () => {
    // const match = useRouteMatch();
    let history = useHistory();
    // 
    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_TOKEN_SAGICC;

    // OBTENER VALORES DEL HEIGTH
    const { userData } = useSelector(({ auth }) => auth);

    const goToChat = () => {
        if (userData) {
            if (window.DOMINIO_JAMAR_PANAMA.includes(window.location.host)) {
                const params = {
                    sagicc_token: TOKEN,
                    primer_apellido: userData.lastname,
                    segundo_apellido: '',
                    primer_nombre: userData.name,
                    segundo_nombre: '',
                    nro_documento: userData.document,
                    tipo_documento: 'CC',
                    telefono: userData.cellphone
                }
                // 
                axios.post('https://jamar.sagicc.co/api/v2/canal-website/website-callback', params).then(result => {
                    // 
                    if (result.data.status === 200 && result.data.result) {
                        // NUMBER.
                        //const whatsapp = (userData.company === 'JA') ? '573222490481' : '5076678-5136';
                        const whatsapp = '5076678-5136';
                        // REDIRRECCIÓN A WHATSAPP
                        window.open(`https://api.whatsapp.com/send?phone=${whatsapp}&text=Hola,%20me%20puedes%20ayudar?`, '_blank');
                    } else {
                        // ALERT - CAMPOS REQUERIDOS.
                        toast.warn('Lo sentimos, ha ocurrido un error al redirigir al chat. Por favor intente mas tarde.', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                });
            } else {
                let urlToGo = `https://jamar.sagicc.co/canal-chat/getChatWidget?token=adfe2a5e298c8d86ca686968afad694a&type=fullscreen&auto_initialize=false&first_name=${userData.name}&last_name=${userData.lastname}&email=${userData.email}`;
                window.open(urlToGo);
            }
        }
    }
    const logout = () => {
        dispatch(userSignOut());
        history.push('/login');
    }

    const goToSelectModule = () => {
        history.push('/auth');
    }

    const styleBtnBack = () => {
        if (userData && userData.account) {
            return { visibility: "visible" };
        } else {
            return { visibility: "hidden" };
        }
    }

    const goToProfile = () => {
        history.push('/jamarAuth/profile');
    }

    return (
        <div className="row conten-main-auth">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 envoltura">
                <div className="row card-banner">
                    <div className="nav-container">
                        <div className="row justify-content-between align-items-center">
                            <Button type="button" className="justify-content-start p-0 button-to-credijamar" onClick={() => goToSelectModule()} style={styleBtnBack()}>
                                <img
                                    src={botonCrediJamar}
                                    alt="Credijamar logo"
                                />
                            </Button>

                            <img
                                src={logoJamar}
                                // className="img-jamar-main"
                                alt="Jamar logo"
                                style={{ height: "26px" }}
                            />

                            <div>
                                <Dropdown className="justify-content-end">
                                    <Dropdown.Toggle variant="link" size="sm" id="burger-more-options">
                                        <Icon id="icon-burger-more-options">menu</Icon>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item className="options-burger" onClick={() => goToProfile()}><Icon className="icons-options-burger">account_circle</Icon>Perfil</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item className="options-burger" onClick={() => logout()}><Icon className="icons-options-burger">exit_to_app</Icon>Cerrar sesión</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center main">
                    <div className="main-container-jamar">
                        <MenuJamar />
                        <HeaderBar />
                        <div className="row justify-content-center">
                            <div className="card card-main secondary-container-jamar">
                                <ViewsJamar />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainAppJamar;