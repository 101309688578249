import { connect } from 'react-redux';
import './scss/BannerRecurrencyComp.scss';
import React from 'react';
import './scss/DisableRecurrency.scss';
//librerias nativas

// Componentes funcionales


const DisableRecurrency = () => {

    return (             
            <div className="centrar">
                <div className="max-960 card-content disable-recurrency">
                    <a  href='/auth/recurrency'> Deseo actualizar mis datos o inactivar mi recurrencia.</a>
                </div>
            </div>   
    )
}



const mapStateToProps = state => state;

export default connect(mapStateToProps, {})(DisableRecurrency);
