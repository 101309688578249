import React, { Component } from 'react';
import http from '../../../https/api';
import './extracts.scss';
import loadingCircle from '../../../assets/images/loading-circle.gif';
import { connect } from 'react-redux';
import CardContainer from '../../../components/CardContainer';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import GavelIcon from '@material-ui/icons/Gavel';
import PersonIcon from '@material-ui/icons/Person';
import FolderIcon from '@material-ui/icons/Folder';
import { ToastContainer } from 'react-toastify';

import ExtractsChoice from "./components/ExtractsChoice";
import CertifiedChoice from './components/CertifiedChoice';
import PeaceChoice from './components/PeaceChoice';
import AccountChoice from './components/AccountChoice';
import { encryptData, getCookie } from '../../../util/util';

class Extracts extends Component {

    pdfBackend =
        (
            window.DOMINIO_JAMAR_PANAMA.includes(window.location.host) ||
            window.DOMINIO_JAMAR_COLOMBIA.includes(window.location.host)
        )
            ? "https://696e3lr892.execute-api.us-east-1.amazonaws.com/api/v1" //Prod
            : "https://bcfgdlgh4c.execute-api.us-east-1.amazonaws.com/api/v1"; //Dev

    constructor(props) {
        super(props);
        this.state = {
            loadingExtracts: true,
            loadingPDF: false,
            isLoading: false,
            buttonFunction: () => {},
            extractsAvailable: [],
            extractSelect: "",
            pdfFile: "",
            pdfName: "",
            selected: false,
            shouldShowButton: false
        }
    }

    iconSize = 40;

    getColor = (className) => {
        return className === this.state.selected.class ? "#00b5ad" : "#aaa";
    }

    /**
     * Evento change select
     * @param {*} event
     */
    changeHandler = (event) => {
        this.setState({ extractSelect: event.target.value }, this.getPDF);
    }

    setIsLoading = (isLoading) => {
        this.setState({
            isLoading
        });
    }

    setPdf = (pdf) => {
        this.setState({
            pdfFile: pdf
        });
    }

    setButtonFunction = (buttonFunction) => {
        this.setState({
            buttonFunction
        });
    }

    setShouldShowButton = (shouldIt) => {
        this.setState({
            shouldShowButton: shouldIt
        });
    }

    isFromPanama = () => {
        return window.DOMINIO_JAMAR_PANAMA.includes(window.location.host);
    };

    CHOICES = [
        {
            name: 'Extracto',
            class: 'extract',
            icon: () => <AttachMoneyIcon style={{ color: this.getColor('extract'), fontSize: this.iconSize }} />,
            extraInfo: () =>
                <ExtractsChoice
                    extractsAvailable={this.state.extractsAvailable}
                    changeExtract={this.changeHandler}
                    extractSelect={this.state.extractSelect}
                />
        },
        {
            name: 'Paz y salvo',
            class: 'peace',
            icon: () => <DoneAllIcon style={{ color: this.getColor('peace'), fontSize: this.iconSize }} />,
            extraInfo: () =>
                <PeaceChoice
                    setButtonFunction={this.setButtonFunction}
                    setShouldShowButton={this.setShouldShowButton}
                    setIsLoading={this.setIsLoading}
                    isLoading={this.state.isLoading}
                    pdfBackend={this.pdfBackend}
                    userData={this.props.userData}
                />
        },
        this.isFromPanama() ? null : {
            name: 'Certificado tributario',
            class: 'certified',
            icon: () => <GavelIcon style={{ color: this.getColor('certified'), fontSize: this.iconSize }} />,
            extraInfo: () =>
                <CertifiedChoice
                    setButtonFunction={this.setButtonFunction}
                    setShouldShowButton={this.setShouldShowButton}
                    setIsLoading={this.setIsLoading}
                    isLoading={this.state.isLoading}
                    pdfBackend={this.pdfBackend}
                    userData={this.props.userData}
                />
        },
        {
            name: 'Cuenta al día',
            class: 'account',
            icon: () => <PersonIcon style={{ color: this.getColor('account'), fontSize: this.iconSize }} />,
            extraInfo: () =>
                <AccountChoice
                    setButtonFunction={this.setButtonFunction}
                    setShouldShowButton={this.setShouldShowButton}
                    setIsLoading={this.setIsLoading}
                    isLoading={this.state.isLoading}
                    pdfBackend={this.pdfBackend}
                    userData={this.props.userData}
                />
        },
    ]

    /**
     * Obtener los extractos disponibles
     */
    getExtracts = () => {
        this.setState({ loadingExtracts: true });
        let urlBase = process.env.REACT_APP_CREDITO;
        let token = getCookie("token");
        let formData = {
            company: this.props.userData.company,
            nide: encryptData(this.props.userData.document)
        }
        // EJECUTAR PETICIÓN.
        http.post(`${urlBase}users/sumaries`, formData, { headers: { 'Authorization': `Bearer ${token}` } }).then(result => {    //20
            // VALIDAR SI LA PETICÓN FUE EXITOSA.
            if (result.data) {
                let extracts = result.data;
                if (Array.isArray(extracts) && extracts.length > 4) {
                    let newListExtracts = [];
                    for (let i = 0; i < 4; i++) {
                        newListExtracts.push(extracts[i]);
                    }
                    this.setState({ extractsAvailable: newListExtracts });
                } else if ((Array.isArray(extracts) && extracts.length <= 4)) {
                    this.setState({ extractsAvailable: extracts });
                }
            }
            this.setState({ loadingExtracts: false });
        }, () => {
            this.setState({ loadingExtracts: false });
        })
    }

    /**
     * Obtener PDF del extracto
     */
    getPDF = () => {
        this.setState({ loadingPDF: true });
        if (this.state.extractSelect) {
            let idSumarie = this.state.extractSelect;
            let urlBase = process.env.REACT_APP_CREDITO;
            let token = getCookie("token");
            let formData = {
                company: this.props.userData.company,
                user_id: encryptData((this.props.userData.id).toString()),
                id_document: encryptData(idSumarie.toString())
            }
            // EJECUTAR PETICIÓN.
            http.post(`${urlBase}users/extract/sumaries`, formData, { headers: { 'Authorization': `Bearer ${token}` } }).then(result => {   //25
                // VALIDAR SI LA PETICÓN FUE EXITOSA.
                if (result.data) {
                    this.setState({
                        pdfFile: result.data.content,
                        pdfName: result.data.file_name,
                        buttonFunction: () => this.downloadPdf("Extracto.pdf"),
                        shouldShowButton: true
                    });
                }
                this.setState({ loadingPDF: false });
            }, () => {
                this.setState({ loadingPDF: false });
            })
        }
    }

    downloadPdf = (name) => {
        const linkSource = `data:application/pdf;base64,${this.state.pdfFile}`;
        const downloadLink = document.createElement("a");
        const fileName = name;

        downloadLink.href = linkSource;
        downloadLink.setAttribute("target", "_blank");
        downloadLink.download = fileName;
        downloadLink.click();
    }

    componentDidMount() {
        this.getExtracts();
    }

    render() {
        let loading = <div className="col-12 text-center"><img className="mt-1" src={loadingCircle} alt="loadingCircle" /></div>;
        return (
          <div className="card-content">
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <ToastContainer />
            <CardContainer
              icon={<FolderIcon style={{ color: "#00b5ad", marginRight: 7 }} />}
              iconIsComponent
              cardTitle="Descarga tus documentos"
              button
              showButton={this.state.selected && this.state.shouldShowButton}
              buttonText="Descargar"
              buttonFunction={this.state.buttonFunction}
            >
              <div id="container">
                <div className={`choose-document ${this.isFromPanama() ? "panama" : ""}`}>
                  {this.CHOICES.map((choice) => (
                    choice && (
                        <React.Fragment key={choice.name}>
                        <div
                            className={`choice-item ${choice.class} ${this.state.selected.class === choice.class ? 'selected' : ''}`}
                            onClick={() =>
                                this.setState({ selected: choice, shouldShowButton: false, buttonFunction: () => {} })
                            }
                        >
                            <span className="choice-item-icon">
                            {choice.icon()}
                            </span>
                            <span className="choice-item-name">{choice.name}</span>
                        </div>
                        </React.Fragment>
                    )
                  ))}
                </div>
                <span className="instructions">Estimado cliente, para abrir su documento debe digitar su número de identificación.</span>
                {
                    this.state.selected && (
                        <div className="document-action">
                            <div className="document-action-title">
                                <span className="document-action-title-icon">
                                    {this.state.selected.icon()}
                                </span>
                                <span className="document-action-title-name">
                                    {this.state.selected.name}
                                </span>
                            </div>
                            <div className="document-action-extra-info">
                                {this.state.selected.extraInfo?.call()}
                            </div>
                        </div>
                    )
                }
                {this.state.loadingPDF ? loading : null}
              </div>
            </CardContainer>
          </div>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    const { userData } = auth;
    return { userData }
};

export default connect(mapStateToProps, {})(Extracts);
