import React, { Component } from 'react';
import { connect } from "react-redux";
import './register.scss';
import Alert from '@material-ui/lab/Alert';
import http from '../../https/api';
import { TextField, Button, Checkbox, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import BtnSubmit from '../../components/btn_submit/index';
import LoadingOverlay from 'react-loading-overlay';
import * as moment from 'moment';
import {
  userSignIn, sessionDataId
} from "../../appRedux/actions/Auth";
import Swal from 'sweetalert2';
import { sha256 } from 'js-sha256';
import logoJamar from "../../assets/images/jamar.png"
import { encryptData } from '../../util/util';

class RegisterC extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formControls: {
        first_name: '',
        last_name: '',
        document: '',
        email: '',
        cellphone: '',
        birthday: '',
        password: '',
        phone02: '',
        confirm_email: '',
        confirm_password: ''
      },
      intdisabled: true,
      intdisabledbuttom: true,
      isLoggedIn: false,
      idMessageIn: false,
      MessageIn: '',
      companyUser: '',
      typeMessage: false,
      loandingGlobal: false,
      contentTerm: null,
      openModal: false
    }
  }

  time = null;
  /**
   * MÉTODO PARA DISPARAR LA FUNCIÓN DE VERIFICACIÓN DEL DOCUEMENTO.
   * @param {*} event 
   */
  onBlurHandler = event => {
    let value = event.target.value;
    // 
    if (this.time) {
      clearInterval(this.time);
    }
    if (value) {
      this.time = setInterval(() => {
        this.documentValid(value);
        clearInterval(this.time);
      }, 700);
    }
  }

  /**
   * MÉTODO GUARDAR LOS CAMBIO DE LOS CAMPOS DEL FORMULARIO.
   * @param {*} event 
   */
  changeHandler = event => {
    this.setState({
      formControls: {
        ...this.state.formControls,
        [event.target.name]: event.target.value
      },

    });
  }
  //Función para verificar que se esté ingresando correctamente el número de celular
  validarCelular = (cellphone) => {
    //validación celular Colombia
    if (window.DOMINIO_JAMAR_COLOMBIA.includes(window.location.host)) {
      if ((cellphone.substring(0, 1) <= 2) || (cellphone.length < 10 || (cellphone.length > 10))) {
        this.setState({
          idMessageIn: true,
          MessageIn: 'Por favor verifique su número de celular.',
          typeMessage: 'warning',
          isLoggedIn: false
        });
        // 
      } else {
        this.setState({
          idMessageIn: false,
          MessageIn: '',
          typeMessage: '',
          isLoggedIn: false
        });
      }
    } else if (window.DOMINIO_JAMAR_PANAMA.includes(window.location.host)) {
      if ((cellphone.substring(0, 1) <= 2) || (cellphone.length < 8 || cellphone.length > 8)) {
        this.setState({
          idMessageIn: true,
          MessageIn: 'Por favor verifique su número de celular.',
          typeMessage: 'warning',
          isLoggedIn: false
        });
        // 
      } else {
        this.setState({
          idMessageIn: false,
          MessageIn: '',
          typeMessage: '',
          isLoggedIn: false
        });
      }
    }
  }
  /**
   * MÉTODO PARA REGISTRAR LOS DATOS DEL USUARIO.
   * @param {*} event 
   */
  signUp = (event) => {
    event.preventDefault();
    // REASIGNAR ESTADO DE BTN LOANDING Y MESSAGE.
    this.setState({ isLoggedIn: true, idMessageIn: false });
    // 
    const { password, confirm_password, email, confirm_email, cellphone } = this.state.formControls;
    // 
    let valid_frm = true;
    // VALIDAR QUE LAS CONTRASEÑAS COINCIDAD.
    if (password !== confirm_password) {
      // MENSAJE
      this.setState({
        idMessageIn: true,
        MessageIn: 'Las contraseñas no coincide, por favor confirme la contraseña.',
        typeMessage: 'warning',
        isLoggedIn: false
      });
      // 
      valid_frm = false;
    }
    // VALIDAR CORREO COINCIDA.
    if (email !== confirm_email) {
      // MENSAJE
      this.setState({
        idMessageIn: true,
        MessageIn: 'El correo no coincide, por favor confirme el correo.',
        typeMessage: 'warning',
        isLoggedIn: false
      });
      // 
      valid_frm = false;
    }
    //validación celular Colombia
    if (window.DOMINIO_JAMAR_COLOMBIA.includes(window.location.host)) {
      if ((cellphone.substring(0, 1) <= 2) || (cellphone.length < 10 || (cellphone.length > 10))) {
        this.setState({
          idMessageIn: true,
          MessageIn: 'Por favor verifique su número de celular.',
          typeMessage: 'warning',
          isLoggedIn: false
        });
        // 
        valid_frm = false;
      }
    } else if (window.DOMINIO_JAMAR_PANAMA.includes(window.location.host)) {
      if ((cellphone.substring(0, 1) <= 2) || (cellphone.length < 8 || cellphone.length > 8)) {
        this.setState({
          idMessageIn: true,
          MessageIn: 'Por favor verifique su número de celular.',
          typeMessage: 'warning',
          isLoggedIn: false
        });
        // 
        valid_frm = false;
      }
    }
    // 
    const formControls = this.state.formControls;
    // PARAMETROS.
    const FormData = {
      company: this.state.companyUser,
      document: encryptData(formControls.document),
      first_name: encryptData(formControls.first_name),
      last_name: encryptData(formControls.last_name),
      birthday: encryptData(moment(formControls.birthday).format('DD-MM-YYYY')),
      email: encryptData(formControls.email.toLowerCase()),
      cellphone: encryptData(formControls.cellphone),
      password: encryptData(sha256(formControls.password))
    }
    // 
    if (valid_frm) {
      const urlBase = process.env.REACT_APP_INGRESO;
      // EJECUTAR PETICIÓN.
      http.post(`${urlBase}users`, FormData).then(result => { //9
        // VALORES POR DEFECTO.
        let params = { show: true, message: '', typeMessage: 'info' }
        // VALIDAR STATUS - OK.
        if (result.status === 201) {
          // VALIDAR SI LA PETICÓN FUE EXITOSA.
          if (result.data) {
            // VALIDAR RETIRNO DATOS DEL USUARIO.
            if (result.data.document) {
              // DATOS DEL USUARIO.
              this.props.userSignIn(result.data);
              // MESAJE PARA EL USUARIO.
              params.show = false;
              // ALERT PARA EL USUARIO.
              Swal.fire({
                title: '<b class="title-register-complete">!FELICIDADES, HAS COMPLETADO TU REGISTRO!</b>',
                html:
                  '<p class="body-register-complete m-0">Ahora podrás <b class="enjoy-text">Disfrutar</b> de todos los <b>Beneficios</b> que tenemos para ti.</p>',
                showCancelButton: false,
                confirmButtonText: 'CONTINUAR',
                confirmButtonColor: '#c52632'
              }).then((result) => {
                if (result.value) {
                  this.props.history.push(`/securityCode`);
                }
              });
            }
          }
        }
        // VALIDAR STATUS - DOCUMENTO REGISTRADO EN LA APP.
        if (result.status === 452) {
          // MESAJE PARA EL USUARIO.
          params.message = 'El documento ya está registrado en la plataforma.';
        }
        // VALIDAR STATUS - DOCUMENTO REGISTRADO EN LA APP.
        if (result.status === 453) {
          // MESAJE PARA EL USUARIO.
          params.typeMessage = 'error';
          params.message = 'El documento no se encuentra registrado en Jamar';
        }
        // VALIDAR STATUS - DOCUMENTO REGISTRADO EN LA APP.
        if (result.status === 454) {
          // MESAJE PARA EL USUARIO.
          params.typeMessage = 'warning';
          params.message = 'El correo ya se encuentra registrado en la plataforma.';
        }
        // VALIDAR STATUS - DOCUMENTO REGISTRADO EN LA APP.
        if (result.status === 455) {
          // MESAJE PARA EL USUARIO.
          params.typeMessage = 'warning';
          params.message = 'La compañía a la que pertenece el usuario no existe.';
        }
        // VALIDAR STATUS - DOCUMENTO REGISTRADO EN LA APP.
        if (result.status === 456) {
          // MESAJE PARA EL USUARIO.
          params.typeMessage = 'warning';
          params.message = 'El número de celular ya se encuentra registrado en la plataforma';
        }
        this.setState({
          idMessageIn: params.show,
          MessageIn: params.message,
          typeMessage: params.typeMessage,
          intdisabled: false,
          isLoggedIn: false
        });
      }).catch((e) => {
        // REASIGNAR MESAGE DE ERROR DE CREDENCIALES.
        this.setState({
          idMessageIn: true,
          MessageIn: 'Lo sentimos, ha ocurrido un error en el sistema.',
          typeMessage: 'error',
          isLoggedIn: false
        });
      })
    }
  }

  /**
     * MÉTODO PARA VALIDAR EL DOCUMENTO.
     * @param {*} document 
     */
  documentValid = (document) => {
    // REASIGNAR ESTADO DE BTN LOANDING Y MESSAGE.
    this.setState({ idMessageIn: false, loandingGlobal: true, intdisabledbuttom: true, intdisabled: true });
    const urlBase = process.env.REACT_APP_INGRESO;
    const formData = { n_ide: encryptData(document) };
    // 
    http.post(`${urlBase}users/validate`,formData).then(result => { //8
      // VALORES POR DEFECTO.
      let params = { show: false, message: '', typeMessage: 'info', intdisabled: true }
      // VALIDAR STATUS - OK.
      if (result.status === 200) {
        // VALIDAR SI LA PETICÓN FUE EXITOSA.
        if (result.data) {
          params.intdisabled = false;
          // HABILITAR INPUTS DEL FORMULARIO.
          this.setState({ idMessageIn: false, companyUser: result.data.company, intdisabled: false, intdisabledbuttom: false });
          // OBTENER TERMINOS Y CONDICIONES.
          this.getTermsCondition();
        }
      }
      // VALIDAR STATUS - DOCUMENTO REGISTRADO EN LA APP.
      if (result.status === 452) {
        // MESAJE PARA EL USUARIO.
        params.show = true;
        params.intdisabled = true;
        params.message = 'Cliente ya registrado en plataforma, inicie sesión .';
      }
      // VALIDAR STATUS - DOCUMENTO NO ESTA REGISTRADO EN CREDIJAMAR.
      if (result.status === 453) {
        // MESAJE PARA EL USUARIO.
        params.show = true;
        params.intdisabled = true;
        params.message = 'El documento ingresado, no es cliente jamar.';
        this.setState({ idMessageIn: false, companyUser: result.data.company });
      }
      // VALIDAR SI HAY MENSAJE.
      if (params.show) {
        this.setState({
          idMessageIn: params.show,
          MessageIn: params.message,
          typeMessage: params.typeMessage,
          intdisabled: params.intdisabled
        });
      }
      // 
      this.setState({ loandingGlobal: false });
    }).catch((e) => {
      // MENSAGE DE ERROR.
      this.setState({
        idMessageIn: true,
        MessageIn: 'Lo sentimos, ha ocurrido un error en el sistema.',
        typeMessage: 'error',
        loandingGlobal: false
      });
    })
  }
  /**
   * 
  */
  getTermsCondition = () => {
    // EJECUTAR PETICIÓN.
    http.get(`${this.state.companyUser}/term`, FormData).then(result => {
      // VALIDAR STATUS - OK.
      if (result.status === 200) {
        // ASIGNAR CONTENIDO HTML.
        this.setState({ contentTerm: result.data.value });
      }
    });
  }
  /**
   * 
  */
  showTerm = () => {
    // VALIDAR SI ESTA CARGADO EL CONTENIDO
    if (this.state.contentTerm) {
      this.setState({ openModal: true });
    }
  }

  login = () => {
    this.props.history.push(`/login`);
  }

  render() {
    window.addEventListener('resize', this.handleResize)
    return (
      <div className="row conten-register w-100">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 envoltura">
          <div className="row">
            <div className="card col-lg-12 col-md-12 col-sm-12 col-12 card-banner">
              <div className="row justify-content-center">
                <img
                  src={logoJamar}
                  className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-5 mb-4"
                  alt="Jamar logo"
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <LoadingOverlay className="col-xl-5 col-lg-7 col-md-8 col-sm-10 col-9 card-form" active={this.state.loandingGlobal} spinner text='Espere por favor, Validando...'>
              <form onSubmit={this.signUp}>
                <div className="col-12">
                  <div className="row mb-2 options">
                    <span className="col-6 text-center" onClick={this.login}>INICIAR SESIÓN</span>
                    <span className="col-6 text-center">REGISTRARSE</span>
                  </div>
                </div>
                {/* MESSAGE DE ERROR O CREDENCIALES INVALIDAS */}
                <div className="inputs-register">
                  {this.state.idMessageIn ? <div className="form-group"><Alert severity={this.state.typeMessage}>{this.state.MessageIn}</Alert></div> : null}
                  {/* INPUT */}
                  <div className="row">
                    <div className="form-group col-lg-5 col-md-6 col-sm-12 col-12">
                      <TextField className="col-lg-12" name="document" id="document" value={this.state.formControls.document} onChange={this.changeHandler} onBlur={this.onBlurHandler} label="Número de identificación" required />
                    </div>
                    <div className="form-group col-lg-5 offset-xl-2 offset-lg-2 col-md-6 col-sm-12 col-12">
                      <TextField className="col-lg-12" name="first_name" id="first_name" value={this.state.formControls.first_name} onChange={this.changeHandler} disabled={this.state.intdisabled} label="Nombre" required />
                    </div>
                    <div className="form-group col-lg-5 col-md-6 col-sm-12 col-12">
                      <TextField className="col-lg-12" name="last_name" id="last_name" value={this.state.formControls.last_name} onChange={this.changeHandler} disabled={this.state.intdisabled} label="Apellidos" required />
                    </div>
                    <div className="form-group col-lg-5 offset-xl-2 offset-lg-2 col-md-6 col-sm-12 col-12">
                      <TextField className="col-lg-12" type="date" name="birthday" id="birthday" label="Fecha de nacimiento" value={this.state.formControls.birthday} onChange={this.changeHandler} disabled={this.state.intdisabled} InputLabelProps={{ shrink: true }} />
                    </div>
                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-12">
                      <TextField className="col-lg-5 col-md-6 col-sm-12 col-12" type="number" name="cellphone" id="cellphone" value={this.state.formControls.cellphone} onChange={this.changeHandler} disabled={this.state.intdisabled} label="Teléfono celular" onInput={(e) => {
                        this.validarCelular(e.target.value)
                      }} required />
                    </div>
                    <div className="form-group col-lg-5 col-md-6 col-sm-12 col-12">
                      <TextField className="col-lg-12" type="email" name="email" id="email" value={this.state.formControls.email} onChange={this.changeHandler} disabled={this.state.intdisabled} label="Correo electrónico" required />
                    </div>
                    <div className="form-group col-lg-5 offset-xl-2 offset-lg-2 col-md-6 col-sm-12 col-12">
                      <TextField className="col-lg-12" type="email" name="confirm_email" id="confirm_email" value={this.state.formControls.confirm_email} onChange={this.changeHandler} disabled={this.state.intdisabled} label="Confirmar correo electrónico" required />
                    </div>
                    <div className="form-group col-lg-5 col-md-6 col-sm-12 col-12">
                      <TextField className="col-lg-12" type="password" name="password" id="password" value={this.state.formControls.password} onChange={this.changeHandler} disabled={this.state.intdisabled} label="Contraseña" required />
                    </div>
                    <div className="form-group col-lg-5 offset-xl-2 offset-lg-2 col-md-6 col-sm-12 col-12">
                      <TextField className="col-lg-12" type="password" name="confirm_password" id="confirm_password" value={this.state.formControls.confirm_password} onChange={this.changeHandler} disabled={this.state.intdisabled} label="Confirmar contraseña" required />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-10 col-11 text-center form-group">
                      <FormControlLabel
                        control={<Checkbox value="checkedA" color="primary" disabled={this.state.intdisabled} inputProps={{ 'aria-label': 'Checkbox A' }} required />}
                        label={(<label className="term-text">Acepto los <span style={{ fontWeight: 600, textDecoration: "underline", cursor: "pointer" }} onClick={this.showTerm}>términos y condiciones</span> y autorizo el Tratamiento de datos personales</label>)}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="cont-buttons"> */}
                <div className="row justify-content-center">
                  <div className="content-btn-log btn-content mt-2 mb-3">
                    <BtnSubmit title="Registrarme" isLoggedIn={this.state.isLoggedIn} disabled={this.state.intdisabledbuttom}></BtnSubmit>
                  </div>
                </div>
              </form>
            </LoadingOverlay>

            <div>
              <Dialog
                open={this.state.openModal}
                onClose={() => this.setState({ openModal: false })}
                maxWidth="md"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Términos y Condiciones"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description" dangerouslySetInnerHTML={{ __html: this.state.contentTerm }}></DialogContentText>
                </DialogContent>
                <DialogActions className="text-center">
                  <Button onClick={() => this.setState({ openModal: false })} style={{ backgroundColor: "#c42531", color: "white" }} autoFocus>
                    ¡Entendido!
              </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser, SessionData } = auth;
  return { authUser, SessionData }
};

export default connect(mapStateToProps, {
  userSignIn,
  sessionDataId
})(RegisterC);
