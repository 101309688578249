import React, { useState } from "react";
import http from "../../../../../https/api";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import loadingCircle from "../../../../../assets/images/loading-circle.gif";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { encryptData, getCookie } from "../../../../../util/util";

const getYearsBack = (back) => {
  const year = new Date().getFullYear() - 1;
  return Array.from({ length: back }, (_, i) => year - back + i + 1);
};

const CertifiedChoice = ({
  userData,
  setIsLoading,
  isLoading,
  setButtonFunction,
  setShouldShowButton,
}) => {
  const [noPdf, setNoPdf] = useState(false);
  const [year, setYear] = useState("");

  const getDocument = async (yearToFetch) => {
    console.log("año: ", yearToFetch);
    if (yearToFetch) {
      console.log("entra: ", yearToFetch);
      setIsLoading(true);
      setShouldShowButton(false);
      setButtonFunction(() => {});
      setNoPdf(false);
      console.log('variable: ', process.env.REACT_APP_CREDITO);
      let urlBase = process.env.REACT_APP_CREDITO;
      let token = getCookie('token');
      let formData = {
        company: userData.company,
        document_name: encryptData('Declaracion'),
        document_data: [
          {
            'name': 'key_1',
            'value': userData.company,
            'decrypt': false
          },
          {
            'name': 'key_2',
            'value': encryptData(userData.document),
            'decrypt': true
          },
          {
            'name': 'key_3',
            'value': encryptData(userData.agency),
            'decrypt': true
          },
          {
            'name': 'key_4',
            'value': encryptData(userData.account),
            'decrypt': true
          },
          {
            'name': 'key_5',
            'value': userData.credit_type,
            'decrypt': false
          },
          {
            'name': 'key_6',
            'value': yearToFetch,
            'decrypt': false
          }
        ]
      }
      console.log('params: ', formData);
      const pdfResponse = await http.post(
        `${urlBase}orchestrator`,  //32
        formData,
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      if (pdfResponse?.data?.documento) {
        setButtonFunction(() => {
          window.open(pdfResponse.data.documento);
        });
        setShouldShowButton(true);
      } else {
        setNoPdf(true);
      }
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="offset-5 col-7">
          <img className="mt-1" src={loadingCircle} alt="loadingCircle" />
        </div>
      ) : (
        <React.Fragment>
          <FormControl>
            <span className="select-tip">Selecciona un año</span>
            <InputLabel id="select-label">AAAA</InputLabel>
            <Select
              id="extractSelect"
              IconComponent={ExpandMoreIcon}
              value={year}
              onChange={(e) => {
                setYear(e.target.value);
                getDocument(e.target.value);
              }}
            >
              <MenuItem value={""}>Seleccione...</MenuItem>
              {getYearsBack(3).map((year) => (
                <MenuItem value={year} key={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {noPdf && (
            <span style={{ display: "block", marginTop: 20, fontSize: "1rem" }}>
              Certificado no disponible para el año {year}
            </span>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default CertifiedChoice;
