import React from 'react';

function Formsecure(props) {

    return (
        <div className="formSecure">
    <form>
        <div className="form-row">
            <div className="col-md-6 mb-3">
                <label htmlFor="validationDefault01">Nombres</label>
                <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    value={props.formData.firstName}
                    onChange={props.handleFormChange}
                    required
                />
            </div>
            <div className="col-md-6 mb-3">
                <label htmlFor="validationDefault02">Apellidos</label>
                <input type="text"
                    className="form-control"
                    name="lastName"
                    id="lastName"
                    value={props.formData.lastName}
                    onChange={props.handleFormChange}
                    required/>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-6 mb-3">
                <label htmlFor="validationDefault03">Correo</label>
                <input type="email" className="form-control" name="email"
                    id="email"
                    value={props.formData.email}
                    onChange={props.handleFormChange} required/>
            </div>
            <div className="col-md-6 mb-3">
                <label htmlFor="validationDefault03">Teléfono</label>
                <input type="number" className="form-control" name="phone"
                id="phone"
                value={props.formData.phone}
                onChange={props.handleFormChange}
                required/>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-6 mb-3">
                <label htmlFor="validationDefault03">Ciudad</label>
                <input type="text" className="form-control" name="city"
                    id="city"
                    value={props.formData.city}
                    onChange={props.handleFormChange} required/>
            </div>
            <div className="col-md-6 mb-3">
                <label htmlFor="validationDefault03">País</label>
                <select className="form-control" name="state" id="state" value={props.formData.state} onChange={props.handleFormChange} required>
                    <option value="" disabled>Selecciona un país</option>
                                    <option value="PA">Panamá (PA)</option>
                                    <option value="AF">Afganistán (AF)</option>
                                    <option value="AL">Albania (AL)</option>
                                    <option value="DZ">Argelia (DZ)</option>
                                    <option value="AD">Andorra (AD)</option>
                                    <option value="AO">Angola (AO)</option>
                                    <option value="AI">Anguila (AI)</option>
                                    <option value="AQ">Antártida (AQ)</option>
                                    <option value="AR">Argentina (AR)</option>
                                    <option value="AM">Armenia (AM)</option>
                                    <option value="AW">Aruba (AW)</option>
                                    <option value="AU">Australia (AU)</option>
                                    <option value="AT">Austria (AT)</option>
                                    <option value="AZ">Azerbaiyán (AZ)</option>
                                    <option value="BS">Bahamas (BS)</option>
                                    <option value="BH">Bahréin (BH)</option>
                                    <option value="BD">Bangladesh (BD)</option>
                                    <option value="BB">Barbados (BB)</option>
                                    <option value="BY">Bielorrusia (BY)</option>
                                    <option value="BE">Bélgica (BE)</option>
                                    <option value="BZ">Belice (BZ)</option>
                                    <option value="BJ">Benín (BJ)</option>
                                    <option value="BM">Bermudas (BM)</option>
                                    <option value="BT">Bután (BT)</option>
                                    <option value="BO">Bolivia (BO)</option>
                                    <option value="BA">Bosnia y Herzegovina (BA)</option>
                                    <option value="BW">Botsuana (BW)</option>
                                    <option value="BR">Brasil (BR)</option>
                                    <option value="IO">Territorio Británico del Océano Índico (IO)</option>
                                    <option value="VG">Islas Vírgenes Británicas (VG)</option>
                                    <option value="BN">Brunéi (BN)</option>
                                    <option value="BG">Bulgaria (BG)</option>
                                    <option value="BF">Burkina Faso (BF)</option>
                                    <option value="BI">Burundi (BI)</option>
                                    <option value="KH">Camboya (KH)</option>
                                    <option value="CM">Camerún (CM)</option>
                                    <option value="CA">Canadá (CA)</option>
                                    <option value="CV">Cabo Verde (CV)</option>
                                    <option value="KY">Islas Caimán (KY)</option>
                                    <option value="CF">República Centroafricana (CF)</option>
                                    <option value="TD">Chad (TD)</option>
                                    <option value="CL">Chile (CL)</option>
                                    <option value="CN">China (CN)</option>
                                    <option value="CX">Isla de Navidad (CX)</option>
                                    <option value="CC">Islas Cocos (CC)</option>
                                    <option value="CO">Colombia (CO)</option>
                                    <option value="KM">Comoras (KM)</option>
                                    <option value="CK">Islas Cook (CK)</option>
                                    <option value="CR">Costa Rica (CR)</option>
                                    <option value="HR">Croacia (HR)</option>
                                    <option value="CU">Cuba (CU)</option>
                                    <option value="CW">Curazao (CW)</option>
                                    <option value="CY">Chipre (CY)</option>
                                    <option value="CZ">República Checa (CZ)</option>
                                    <option value="CD">República Democrática del Congo (CD)</option>
                                    <option value="DK">Dinamarca (DK)</option>
                                    <option value="DJ">Yibuti (DJ)</option>
                                    <option value="DM">Dominica (DM)</option>
                                    <option value="DO">República Dominicana (DO)</option>
                                    <option value="TL">Timor Oriental (TL)</option>
                                    <option value="EC">Ecuador (EC)</option>
                                    <option value="EG">Egipto (EG)</option>
                                    <option value="SV">El Salvador (SV)</option>
                                    <option value="GQ">Guinea Ecuatorial (GQ)</option>
                                    <option value="ER">Eritrea (ER)</option>
                                    <option value="EE">Estonia (EE)</option>
                                    <option value="ET">Etiopía (ET)</option>
                                    <option value="FK">Islas Malvinas (FK)</option>
                                    <option value="FO">Islas Feroe (FO)</option>
                                    <option value="FJ">Fiyi (FJ)</option>
                                    <option value="FI">Finlandia (FI)</option>
                                    <option value="FR">Francia (FR)</option>
                                    <option value="PF">Polinesia Francesa (PF)</option>
                                    <option value="GA">Gabón (GA)</option>
                                    <option value="GM">Gambia (GM)</option>
                                    <option value="GE">Georgia (GE)</option>
                                    <option value="DE">Alemania (DE)</option>
                                    <option value="GH">Ghana (GH)</option>
                                    <option value="GI">Gibraltar (GI)</option>
                                    <option value="GR">Grecia (GR)</option>


                </select>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-6 mb-3">
                <label htmlFor="validationDefault03">Dirección</label>
                <input type="text" className="form-control" name="address"
                    id="address"
                    value={props.formData.address}
                    onChange={props.handleFormChange} placeholder="AV. 124 Ciudad de Panama"required/>
            </div>
            <div className="col-md-6 mb-3">
                <label htmlFor="validationDefault05">Código Postal</label>
                <input type="text" className="form-control" name="zip"
                    id="zip"
                    value={props.formData.zip}
                    onChange={props.handleFormChange} required/>
            </div>
        </div>
        <div className="col-auto pl-0 pr-0">
            <button type="submit" className="btn btn-primary mb-3 w-100 pb-3 pt-2 border-0" style={{backgroundColor:'#127a67'}} onClick={props.handleSubmit} >Pagar</button >
        </div>
    </form>
</div>

    );
}

export default Formsecure;
