import axios from 'axios';

const axiosApi = axios.create();

axiosApi.interceptors.request.use(
    async config => {
        config.baseURL = process.env.REACT_APP_RECAUDO_EXTERNO_GENERAL_API_WL;
        config.headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        config.transformResponse = axios.defaults.transformResponse.concat((response) => {
            // RESPUESTA POR DEFECTO.
            return response;
        });
        config.validateStatus = (status) => {
            return status >= 200 && status < 500; // default
        };
        return config;
    }
)
    
   
    //? 'https://appsprd.mueblesjamar.com.co/GeneralesService/api/' //Prod
    

export default axiosApi;