import React, { useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
// memo - , useLocation
import { Switch, Route, useRouteMatch } from 'react-router-dom';
// ROUTER INTERNO DE LA APP.
//import MainApp from "./MainApp";
//import MainAppJamar from "./mainAppJamar";
//import Login from '../../pages/login/login';
//import Register from '../../pages/register-jamar/register';
//import confirmPass from '../../pages/confirmPassword/NewPassword';
//import securityCode from '../../pages/securityCode/SegurityCode';
//import RecoverPassword from "../../pages/recoverPassword/recoverPassword";
//import Menu from "../../pages/menu/menu";
//import Onboarding from "../../pages/onboarding/onboarding";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ToastContainer } from 'react-toastify';
import useCurrentHeight from '../../util/useCurrentHeight';

import { setHeightGlobal } from "../../appRedux/actions/Setting";
//import RecoveryPassword from "../../pages/recoveryPassword/recoveryPassword";
// import { LOGGED_USER_SESSION } from "../../constants/ActionTypes";

//import menu_chose from '../../pages/views/menu/menu';

import MainPagos from "./MainPagos";


const AppPagos = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const alto = useCurrentHeight();
    const altoFooter = 30;
    const scrollRef = useRef();
    const getSectionRouter = useCallback(() => {
        return (alto - altoFooter);
    }, [alto, altoFooter]);
    useEffect(() => {
        const maxHeight = getSectionRouter();
        dispatch(setHeightGlobal(maxHeight));
    }, [dispatch, getSectionRouter]);


    useEffect(() => {
        window.scrollRef = scrollRef.current;
    }, [])

    return (
        <div className="App" style={{ height: (alto) + 'px', overflow: "hidden" }}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <ToastContainer />
            <PerfectScrollbar containerRef={el => scrollRef.current = el} className="content-app row justify-content-center" style={{ height: getSectionRouter() + 'px', overflow: "hidden" }}>
                <Switch>
                {<Route path={`${match.url}`} component={MainPagos} />}

                </Switch>
            </PerfectScrollbar>
            <div className="content-app row justify-content-center" style={{ padding: 5, color: "#e0e0e0", fontSize: 10, height: altoFooter + 'px', overflow: "hidden", backgroundColor: "#414141" }}>
                &copy; {new Date().getFullYear()} pagos Jamar S.A. Todos los derechos reservados
            </div>
        </div>
    );
}

export default AppPagos;
