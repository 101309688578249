import axios from 'axios';
import yapi from '../../../assets/images/pagos/yapi.png';
import clave_ from '../../../assets/images/pagos/clave_.png';
import credit_card from '../../../assets/images/pagos/credit_card.png';
import { getCompany } from '../../../util/util';

export const methorTwo = async (amount, redirect, acount) => {
  try {
    const dataPay = await axios.get(
      (window.DOMINIO_JAMAR_PANAMA.includes(window.location.host) || window.DOMINIO_JAMAR_COLOMBIA.includes(window.location.host))
         ?'https://4peilixag7.execute-api.us-east-1.amazonaws.com/prd/clave/url/JP' //Prod
          : 'https://y7td0kv6xh.execute-api.us-east-1.amazonaws.com/dev/clave/url/JP' //dev,            
      , {
        params: {
          amount: amount,
          returnUrl: redirect,
          acount: acount
        }
      });
    const response = await dataPay;
    console.log(response)
    return response;

  } catch (error) {
    console.log(error);
    return error
  }
};

export const methodYappi = async (amount, redirect, acount) => {
  try {
    const dataPay = await axios.post(
      (window.DOMINIO_JAMAR_PANAMA.includes(window.location.host) || window.DOMINIO_JAMAR_COLOMBIA.includes(window.location.host))
        ? 'https://jamaryappy.com/pagosbgurl2' //Prod
        : 'https://jamaryappy.com/pagosbgurl2' //dev,            
      , {
        "cuenta": acount,
        "total": amount.toString(),
        "subtotal": amount.toString(),
        "shipping": "0",
        "discount": "0",
        "taxes": "0",
        "successUrl": `${window.location.origin}/pagos/confirmacionpagoyappi`,
        "failUrl": `${window.location.origin}/pagos/errorpagoyappi`,
        //        "successUrl": `https://jamaryappy.com/pagos/confirmacionpagoyappi`,
        //        "failUrl": `https://jamaryappy.com/pagos/errorpagoyappi`,

        "domain": redirect,
        "tel": ""

      }
    );
    const response = await dataPay;
    return response;
  } catch (error) {
    console.log("ha ocurido un error", error);
    return error
  }
};

export const validatePay = async (data) => {
  try {
    const dataPay = await axios.get(
      (window.DOMINIO_JAMAR_PANAMA.includes(window.location.host) || window.DOMINIO_JAMAR_COLOMBIA.includes(window.location.host))
        ? 'https://4peilixag7.execute-api.us-east-1.amazonaws.com/prd/clave/validate/JP' //Prod
        : 'https://y7td0kv6xh.execute-api.us-east-1.amazonaws.com/dev/clave/validate/JP' //dev,
      , {
        params: {
          sessionId: data
        }
      });
    const response = await dataPay;
    response.data.success = true;
    return response.data;

  } catch (error) {
    return {
      success: false
    }
  }
};
export const validatePayYappi = async (data) => {
  try {
    const dataPay = await axios.get(
      (window.DOMINIO_JAMAR_PANAMA.includes(window.location.host) || window.DOMINIO_JAMAR_COLOMBIA.includes(window.location.host))
        ? 'https://jamaryappy.com' //Prod
        : 'https://jamaryappy.com' //dev,
      , {
        params: {
          id: data
        }
      });
    const response = await dataPay;
    response.data.success = true;
    return response.data;

  } catch (error) {
    return {
      success: false
    }
  }
};

export const listMethods = async () => {
    const urlBase = process.env.REACT_APP_BASE_PORTAL_BACKEND;
    const company = await getCompany();
    const a = await axios.get(
      `${urlBase}proveedores/${company}`
      , {
        params: {}
      })

    if( a.status !== 200) {
      return [];
    }

    let resp = [];
    for( let i of a.data.data ) {
      if(i.N_IDE === '9928' && i.CANAL === 'T_DEBITO') {
        resp.push({
          title: i.TEXTO_BOTON,
          img: clave_,
          estado: i.ESTADO,
          canal: i.CANAL,
          n_ide: i.N_IDE,
          valor_minimo: i.PAGO_MINIMO,
          redirect: 'http://localhost:3000/pagos/confirmacionpago',
          action: methorTwo
        })
      }

      if(i.N_IDE === '9928' && i.CANAL === 'T_CREDITO') {
        resp.push({
          title: i.TEXTO_BOTON,
          img: credit_card,
          estado: i.ESTADO,
          canal: i.CANAL,
          n_ide: i.N_IDE,
          valor_minimo: i.PAGO_MINIMO,
          redirect: 'http://localhost:3000/pagos/confirmacionpago',
          action: ()=>{}
        })
      }

      if(i.N_IDE === '409006' && i.CANAL === 'YAPPY_WEB') {
        resp.push({
          title: i.TEXTO_BOTON,
          img: yapi,
          estado: i.ESTADO,
          canal: i.CANAL,
          n_ide: i.N_IDE,
          valor_minimo: i.PAGO_MINIMO,
          redirect: "https://jamaryappy.com",
          action: methodYappi
        });
      }
    }

    return resp;
  };

