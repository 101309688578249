import React, { useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
// memo - , useLocation
import { Switch, Route, useRouteMatch } from 'react-router-dom';
// ROUTER INTERNO DE LA APP.
import MainApp from "./MainApp";
import MainAppJamar from "./mainAppJamar";
import Login from '../../pages/login/login';
import Register from '../../pages/register-jamar/register';
import confirmPass from '../../pages/confirmPassword/NewPassword';
import securityCode from '../../pages/securityCode/SegurityCode';
import RecoverPassword from "../../pages/recoverPassword/recoverPassword";
import Menu from "../../pages/menu/menu";
import Onboarding from "../../pages/onboarding/onboarding";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ToastContainer } from 'react-toastify';
import useCurrentHeight from '../../util/useCurrentHeight';

import { setHeightGlobal } from "../../appRedux/actions/Setting";
import RecoveryPassword from "../../pages/recoveryPassword/recoveryPassword";
// import { LOGGED_USER_SESSION } from "../../constants/ActionTypes";

//import menu_chose from '../../pages/views/menu/menu';
//<Route exact path='/chose-menu' component={menu_chose} />

// const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) => <Route
//     {...rest}
//     // render={props => <Component {...props} />}
//     render={props =>
//         // authUser
//         <Component {...props} />
//         // : <Redirect to={{ pathname: '/login', state: { from: location } }} />
//     }
// />;

const App = () => {
    // VALIDAR DE SESI�N.
    // let { authUser } = useSelector(({ auth }) => auth);
    // let _authUser = (authUser) ? authUser : JSON.parse(localStorage.getItem(LOGGED_USER_SESSION));
    const dispatch = useDispatch();
    // 
    const match = useRouteMatch();
    // const location = useLocation();
    //Referencia content-header
    //const contHeader = createRef();
    const alto = useCurrentHeight();
    const altoFooter = 30;

    const scrollRef = useRef();

    const getSectionRouter = useCallback(() => {
        return (alto - altoFooter);
    }, [alto, altoFooter]);
    // 
    useEffect(() => {
        const maxHeight = getSectionRouter();
        dispatch(setHeightGlobal(maxHeight));
    }, [dispatch, getSectionRouter]);


    useEffect(() => {
        window.scrollRef = scrollRef.current;
    }, [])

    // Deshabilita clic derecho y combinaciones para abrir consola de desarrollador en el navegador
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (
            event.keyCode === 123 || // F12
            (event.ctrlKey && event.shiftKey && event.keyCode === 73) // Ctrl+Shift+I
            ) {
            event.preventDefault();
            }
        };

        const handleContextMenu = (event) => {
            event.preventDefault();
        };

        window.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    return (
        <div className="App" style={{ height: (alto) + 'px', overflow: "hidden" }}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <ToastContainer />
            <PerfectScrollbar containerRef={el => scrollRef.current = el} className="content-app row justify-content-center" style={{ height: getSectionRouter() + 'px', overflow: "hidden" }}>
                <Switch>
                    <Route exact path='/' component={Login} />
                    <Route exact path='/login' component={Login} />
                    <Route exact path='/login/:id' component={Login} />
                    <Route exact path='/register' component={Register} />
                    <Route exact path='/recoveryPassword' component={RecoveryPassword} />
                    <Route exact path='/confirmPassword' component={confirmPass} />
                    <Route exact path='/securityCode' component={securityCode} />
                    <Route exact path='/forgetPassword' component={RecoverPassword} />
                    <Route exact path='/selectModule' component={Menu} />
                    <Route exact path='/onboarding' component={Onboarding} />
                    {/* <Route exact path='/sms-validation' component={SmsValidation} /> */}

                    {<Route path={`${match.url}jamarAuth`} component={MainAppJamar} />}
                    {<Route path={`${match.url}auth`} component={MainApp} />}

                    {/* <RestrictedRoute path={`${match.url}jamarAuth`} authUser={_authUser} location={location} component={MainAppJamar} />
                    <RestrictedRoute path={`${match.url}auth`} authUser={_authUser} location={location} component={MainApp} /> */}
                </Switch>
            </PerfectScrollbar>
            <div className="content-app row justify-content-center" style={{ padding: 5, color: "#e0e0e0", fontSize: 10, height: altoFooter + 'px', overflow: "hidden", backgroundColor: "#414141" }}>
                &copy; {new Date().getFullYear()} Muebles Jamar S.A. Todos los derechos reservados
            </div>
        </div>
    );
}

export default App;
