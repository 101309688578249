import React from "react";
import { useEffect } from "react";

const Success = ({ setIsLoading, setShouldShowButton }) => {
  useEffect(() => {
    setIsLoading(false);
    setShouldShowButton(true);
  }, [setIsLoading, setShouldShowButton]);

  return (
    <div style={{ fontSize: "1rem", marginBottom: 20 }}>
      Su contraseña ha sido actualizada con éxito.
    </div>
  );
};

export default Success;
