import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { validatePay,validatePayYappi } from '../select-method/methods';
import { validatePayEpayco } from '../select-method-colombia/methods';

import './style.scss'

import { useHistory } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar';
import CardContainer from '../../../components/CardContainer';
import Alert from '@material-ui/lab/Alert';
import Spinner from 'react-bootstrap/Spinner';

import { Dialog, Button } from '@material-ui/core';

const Confirmacionpago = (props) => {

  const [loading, setLoading] = useState(false);
  const [loadingerror, setLoadingerror] = useState(false);
  const history = useHistory()

  const [dataclient, setDataclient] = useState({
    "account": "",
    "amount": "",
    "sesionId": "",
    "status": "",
    "dataload": false,
    "success": false
  });
  let ppp = 'ppp';
  if (props.match.path.indexOf('auth') > -1) {
    ppp = '';
  }

  useEffect(() => {
    const apiValidateTransaccion = async () => {
      if (props.location.pathname.indexOf('confirmacionpagoyappi') > -1) {
        try {
          const query = new URLSearchParams(props.location.search).get('orderId');
          setLoading(true)
          const response = await validatePayYappi(query);
          setDataclient({
            "account": response?.cuenta,
            "amount": response?.amount,
            "sesionId": query,
            "status": response?.transaccion_estatus,
            "dataload": true,
            "success": true
          })
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setLoadingerror(true);
          setDataclient({
            "account": "",
            "amount": "",
            "sesionId": "",
            "status": "",
            "dataload": true,
            "success": false
          })
          history.push('/pagos')
        }

      } else if (props.location.pathname.indexOf('errorpagoyappi') > -1) {
        try {
          const query = new URLSearchParams(props.location.search).get('orderId');
          setLoading(true)
          const response = await validatePayYappi(query);
          setDataclient({
            "account": response?.cuenta,
            "amount": response?.amount,
            "sesionId": query,
            "status": 4,
            "dataload": true,
            "success": true
          })
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setLoadingerror(true);
          setDataclient({
            "account": "",
            "amount": "",
            "sesionId": "",
            "status": "",
            "dataload": true,
            "success": false
          })
          history.push('/pagos')
        }

      } else if(props.location.pathname.indexOf('confirmacionpayu') > -1){
        const query = new URLSearchParams(props.location.search).get('orderId');
        try {
          setLoading(true)
          const response = await validatePayEpayco(query);
          setDataclient({
            "account": response?.cedula,
            "amount": response?.valor_a_pagar,
            "sesionId": query,
            "status": Number(response?.estado),
            "dataload": true,
            "success": response?.success
          })
          if (!response?.success) {
            setLoadingerror(true);
          }
          setLoading(false);

        } catch (error) {
          setLoading(false);
          setLoadingerror(true);
          setDataclient({
            "account": "",
            "amount": "",
            "sesionId": "",
            "status": "",
            "dataload": true,
            "success": false
          })
          history.push('/pagos/colombia')
        }
      } else {
        const query = new URLSearchParams(props.location.search).get('session_id');
        try {
          setLoading(true)
          const response = await validatePay(query);
          setDataclient({
            "account": response?.cuenta,
            "amount": response?.amount,
            "sesionId": response?.sessionId,
            "status": response?.transaccion_estatus,
            "dataload": true,
            "success": response?.success
          })
          if (!response?.success) {
            setLoadingerror(true);

          }
          setLoading(false);

        } catch (error) {
          setLoading(false);
          setLoadingerror(true);
          setDataclient({
            "account": "",
            "amount": "",
            "sesionId": "",
            "status": "",
            "dataload": true,
            "success": false
          })
          history.push('/pagos')
        }
      }
    }
    apiValidateTransaccion()
  }, [props.location.pathname, props.location.search, history])

  return (
    <div className={"centrar " + ppp} >
      <div className={"card-content max-960 "}>
        <CardContainer style={{ marginTop: '0px !important' }} cardTitle="Datos del pago">
          <PerfectScrollbar>


            <div className="col-12">
              {(dataclient.dataload && !dataclient.success) ? <div className="form-group"><Alert severity='info'>Acurrido un error</Alert></div> : null}
            </div>

            {(dataclient.dataload && dataclient.success) ?
              <div className="col-12">


                <div className="row">
                  <div className="col-lg-12 content-inputs">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <label className="font-weight-bold">Cédula</label>
                        <h6>{dataclient.account}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="font-weight-bold">Identificador de la transacción:  </label>
                        <h6>{dataclient.sesionId} </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="font-weight-bold">
                          Valor pagado:
                          <h6>
                            {dataclient.amount}
                          </h6>
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <label className="font-weight-bold">
                          Estado:
                          <h6>
                            { }
                            {dataclient.status === 3 ? 'Aprobado' : dataclient.status === 1 ? 'Pendiente' : dataclient.status === 2 ? 'Rechazado' : 'Por confirmar'}
                          </h6>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div> : null}
          </PerfectScrollbar>
        </CardContainer >
      </div >

      <Dialog open={loading}>
        <div className="text-center p30">
          <div className="text-center">
            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
          </div>
          <div>
            Validando...
          </div>
        </div>
      </Dialog>

      <Dialog open={loadingerror}>
        <div className="text-center p30">
          <div className="text-center">
            <p>Ha ocurrido un error, vuelva a intentarlo mas tarde</p>
          </div>
          <div className="text-center mt2">
            <Button variant="contained" onClick={() => setLoadingerror(false)} >OK</Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
};

const mapStateToProps = state => state;
export default connect(mapStateToProps, {})(Confirmacionpago);
