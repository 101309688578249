
export const LOADING_COMBO = [{
    codigo: "-1",
    descripcion: "Cargando..."
}];

export const COMBO_INTERIOR_1 = [{
    codigo: "-2",
    descripcion: "N/A"
}, {
    codigo: "MA",
    descripcion: "Manzana"
}, {
    codigo: "BL",
    descripcion: "Bloque"
}, {
    codigo: "PI",
    descripcion: "Piso"
}, {
    codigo: "OI",
    descripcion: "Otro Int."
}];

export const COMBO_INTERIOR_2 = [{
    codigo: "-2",
    descripcion: "N/A"
}, {
    codigo: "AP",
    descripcion: "Apartamento"
},
{
    codigo: "CS",
    descripcion: "Casa"
},
{
    codigo: "LC",
    descripcion: "Local"
},
{
    codigo: "CN",
    descripcion: "Consultorio"
},
{
    codigo: "OF",
    descripcion: "Oficina"
},
{
    codigo: "O3",
    descripcion: "Otro"
},
{
    codigo: "LO",
    descripcion: "Lote"
}];

export const DIR_SELECT = [{
    codigo: "AU",
    descripcion: "Autopista"
}, {
    codigo: "AV",
    descripcion: "Avenida"
}, {
    codigo: "CL",
    descripcion: "Calle"
}, {
    codigo: "CR",
    descripcion: "Carrera"
}, {
    codigo: "DA",
    descripcion: "Diagonal"
}, {
    codigo: "KM",
    descripcion: "Kilómetro"
}, {
    codigo: "OT",
    descripcion: "Otro"
}, {
    codigo: "TR",
    descripcion: "Transversal"
}, {
    codigo: "VI",
    descripcion: "Vía"
}, {
    codigo: "ET",
    descripcion: "Etapa"
}, {
    codigo: "SC",
    descripcion: "Sector"
}, {
    codigo: "UB",
    descripcion: "Urbanizacion"
}];