import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Auth from './Auth';
import Settings from './Setting';
import Purchases from './Purchases';
import PAGOSDATA from './dataPagos';
import Recurrency from './Recurrency';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    purchases: Purchases,
    pagosInfo: PAGOSDATA,
    recurrency: Recurrency
});

export default createRootReducer