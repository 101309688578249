import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import './recoveryPassword.scss';
import { TextField } from '@material-ui/core';
import http from '../../https/api';
import BtnSubmit from '../../components/btn_submit/index';
import Alert from '@material-ui/lab/Alert';
import { sha256 } from 'js-sha256';
import { connect } from 'react-redux';
import logoJamar from "../../assets/images/JamarLogo.svg";
import { encryptData } from '../../util/util';

class RecoveryPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      validateOk: false,
      globalLoading: true,
      loadingChangePassword: false,
      changePasswordSuccess: false,
      idMessageIn: "",
      tokenId: "",
      newPassword: "",
      confirmPassword: "",
      secondsRemaining: 6,
    }
  }

  /**
   * MÉTODO GUARDAR LOS CAMBIO DE LOS CAMPOS DEL FORMULARIO.
   * @param {*} event 
  */
  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  goBack = () => {
    this.props.history.push('/login');
  }

  /**
   * Función que obtiene un parametro de una url dada
   * [QueryParams]
   * @param name Nombre de parametro a buscar
   * @param url Url de la cual buscar
   */
  getParamToUrl = (name, url) => {
    if (!url) url = window.location.href;
    // eslint-disable-next-line
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    let regexS = "[\\?&]" + name + "=([^&#]*)";
    let regex = new RegExp(regexS);
    let results = regex.exec(url);
    return results == null ? null : results[1];
  }

  componentDidMount() {
    this.validateToken();
  }

  /**
   * Enviar mensaje de recuperación al email
  */
  validateToken = () => {
    this.setState({ globalLoading: true });
    let valueToken = this.getParamToUrl("token");
    let urlBase = process.env.REACT_APP_BASE_PORTAL_BACKEND;
    let formData = { token: valueToken };
    http.post(`${urlBase}valid-token`, formData).then(result => {  //12
      let params = { show: true, message: "", typeMessage: "success" }
      if (result.status === 200) {
        if (result.data && result.data.token_id != null) {
          params = { show: false, message: "", typeMessage: "success" }
          this.setState({
            validateOk: true,
            tokenId: result.data.token_id,
            idMessageIn: params.show,
            MessageIn: params.message,
            typeMessage: params.typeMessage,
            globalLoading: false
          });
        } else {
          params.typeMessage = 'error';
          params.message = 'Ocurrió un error. Intente nuevamente.';
          this.setState({
            idMessageIn: params.show,
            MessageIn: params.message,
            typeMessage: params.typeMessage,
            globalLoading: false
          });
          let interval = setInterval(() => {
            this.setState({ secondsRemaining: this.state.secondsRemaining - 1 })
          }, 1000);
          setTimeout(() => {
            clearInterval(interval);
            this.goBack();
          }, 7000);
        }
      }

      if (result.status === 460) {
        //El token no existe
        this.goBack();
      }

      if (result.status === 461) {
        params.typeMessage = 'error';
        params.message = 'El token ya ha fue validado. Si es necesario realice el proceso nuevamente.';
        this.setState({
          idMessageIn: params.show,
          MessageIn: params.message,
          typeMessage: params.typeMessage,
          globalLoading: false
        });
        let interval = setInterval(() => {
          this.setState({ secondsRemaining: this.state.secondsRemaining - 1 })
        }, 1000);
        setTimeout(() => {
          clearInterval(interval);
          this.goBack();
        }, 7000);
      }

      if (result.status === 462) {
        params.typeMessage = 'error';
        params.message = 'El token ya ha caducado. Realice el proceso nuevamente.';
        this.setState({
          idMessageIn: params.show,
          MessageIn: params.message,
          typeMessage: params.typeMessage,
          globalLoading: false
        });
        let interval = setInterval(() => {
          this.setState({ secondsRemaining: this.state.secondsRemaining - 1 })
        }, 1000);
        setTimeout(() => {
          clearInterval(interval);
          this.goBack();
        }, 7000);
      }
    });
  }

  /**
   * Cambiar contraseña
   */
  changePassword = (event) => {
    event.preventDefault();
    this.setState({ loadingChangePassword: true });
    if (this.state.newPassword === this.state.confirmPassword) {
      let formData = {
        id: encryptData((this.state.tokenId).toString()),
        new_password: encryptData(sha256(this.state.newPassword))
      };
      let urlBase = process.env.REACT_APP_INGRESO;
      http.put(`${urlBase}users/change-password`, formData).then(result => { //13
        let params = { show: true, message: "Se ha actualizado su contraseña exitosamente.", typeMessage: "success" }
        if (result.status === 200) {
          this.setState({
            idMessageIn: params.show,
            MessageIn: params.message,
            typeMessage: params.typeMessage,
            loadingChangePassword: false,
            changePasswordSuccess: true
          });
          let interval = setInterval(() => {
            this.setState({ secondsRemaining: this.state.secondsRemaining - 1 })
          }, 1000);
          setTimeout(() => {
            clearInterval(interval);
            this.goBack();
          }, 7000);
        }

        if (result.status === 460) {
          //El token no existe
          this.goBack();
        }

        if (result.status === 461) {
          params.typeMessage = 'error';
          params.message = 'El token no ha sido validado. Realice el proceso nuevamente.';
          this.setState({
            idMessageIn: params.show,
            MessageIn: params.message,
            typeMessage: params.typeMessage,
            loadingChangePassword: false
          });
        }

        if (result.status === 462) {
          params.typeMessage = 'error';
          params.message = 'El token ya fue usado para cambiar la contraseña. Si lo requiere realice el proceso nuevamente.';
          this.setState({
            idMessageIn: params.show,
            MessageIn: params.message,
            typeMessage: params.typeMessage,
            loadingChangePassword: false
          });
        }
      });
    } else {
      let params = { show: true, message: "Las contraseñas no coinciden.", typeMessage: "warning" }
      this.setState({
        idMessageIn: params.show,
        MessageIn: params.message,
        typeMessage: params.typeMessage,
        loadingChangePassword: false
      });
    }
  }

  render() {
    return (
      <div className="row conten-login content-recovery-password">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 envoltura">
          <div className="row">
            <div className="card col-lg-12 col-md-12 col-sm-12 col-12 card-banner">
              <div className="row justify-content-center">
                <img
                  src={logoJamar}
                  className="col-lg-2 col-md-2 col-sm-3 col-6 mb-4"
                  alt="Jamar logo"
                />
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 col-sm-7 col-11">
                  <span className="text d-block text-center">¡Restablece tú contraseña para continuar disfrutando de tus servicios!</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="card col-xl-4 col-lg-5 col-md-6 col-sm-8 col-8 card-form">
              <form onSubmit={this.changePassword}>
                <div className="col-12">
                  <div className="row mb-2 options">
                    <span className="col-12 text-center">NUEVA CONTRASEÑA</span>
                  </div>
                </div>
                {/* MESSAGE DE ERROR O CREDENCIALES INVALIDAS */}
                {this.state.idMessageIn ? <div className="form-group"><Alert severity={this.state.typeMessage}>{this.state.MessageIn}</Alert></div> : null}
                <div className="col-12">
                  {this.state.idMessageIn && this.state.typeMessage !== "warning" ? <Alert severity="info">Será redirigido al inicio en {this.state.secondsRemaining} segundos...</Alert> : null}
                </div>
                <div className="form-group">
                  <TextField type="password" name="newPassword" style={{ width: '100%' }} id="newPassword" value={this.state.newPassword} onChange={this.changeHandler} label="Nueva contraseña" required />
                </div>
                <div className="form-group">
                  <TextField type="password" name="confirmPassword" style={{ width: '100%' }} id="confirmPassword" value={this.state.confirmPassword} onChange={this.changeHandler} label="Confirmar contraseña" required />
                </div>
                {/* <div className="cont-buttons"> */}
                <div className="row justify-content-center">
                  <div className="content-btn-log btn-content mb-3">
                    <BtnSubmit title="Restablecer" isLoggedIn={this.state.loadingChangePassword}></BtnSubmit>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { heightGlobal } = settings;
  return { heightGlobal }
};

export default connect(mapStateToProps, {})(withRouter(RecoveryPassword));