import React, { Component } from 'react';
import { connect } from "react-redux";
import './NewPass.scss';
import { TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import http from '../../https/api';
import BtnSubmit from '../../components/btn_submit/indexRed';
import {
  userSignIn, sessionDataId
} from "../../appRedux/actions/Auth";
import { sha256 } from 'js-sha256';
import logoJamar from "../../assets/images/jamar.png"
import { LOGGED_USER_SESSION } from '../../constants/ActionTypes';
import { encryptData, getCookie } from '../../util/util';
//import Swal from 'sweetalert2';

class confirmPass extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formControls: {
        first_name: '',
        last_name: '',
        document: '',
        email: '',
        cellphone: '',
        birthday: '',
        password: '',
        phone02: '',
        confirm_email: '',
        confirm_password: '',
        company: '',
      },
      isLoggedIn: false,
      idMessageIn: false,
      MessageIn: ''
    }
  }

  componentDidMount() {
    const data = JSON.parse(localStorage.getItem('DATAUSER'));
    if (data) {
      this.setState({
        formControls: {
          ...this.state.formControls,
          first_name: data.primer_nombre,
          last_name: data.primer_apellido,
          document: data.n_ide,
          email: data.correo,
          cellphone: data.celular,
          birthday: data.fecha_nac,
          company: data.c_emp
        }
      });
    }
  }

  changeHandler = event => {
    this.setState({
      formControls: {
        ...this.state.formControls,
        [event.target.name]: event.target.value
      }
    });
  }

  /**
     * MÉTODO PARA ENVIAR CÓDIGO.
     */
  sendCode = (data) => {
    this.setState({ isLoggedIn: true, idMessageIn: false });
    let urlBase = process.env.REACT_APP_BASE_PORTAL_BACKEND;
    let formData = {
      company: data.company,
      user_id: encryptData((data.id).toString())
    };
    // GENERAL CÓDIGO DE VALIDACIÓN
    http.post(`${urlBase}users/security_code`, formData).then(result => {    //10
      // VALIDAR SI LA PETICÓN FUE EXITOSA.
      if (result.status === 201 || result.status === 200) {
        this.setState({
            idMessageIn: true,
            MessageIn: 'Se enviado un código de seguridad a su celular.',
            isLoggedIn: false
        });
        this.props.history.push(`/securityCode`);
      }
      if (result.status === 404) {
        this.setState({
            idMessageIn: true,
            MessageIn: 'El usuario no existe.',
            isLoggedIn: false
        });
      }
      if (result.status === 455) {
        this.setState({
            idMessageIn: true,
            MessageIn: 'La compañía es incorrecta.',
            isLoggedIn: false
        });
      }
    });
  }

  /**
 * MÉTODO PARA REGISTRAR LOS DATOS DEL USUARIO.
 * @param {*} event 
 */
signUp = (event) => {
  event.preventDefault();
  // REASIGNAR ESTADO DE BTN LOANDING Y MESSAGE.
  this.setState({ isLoggedIn: true, idMessageIn: false });
  // 
  const formControls = this.state.formControls;

  // if(formControls.password && formControls.password)
  // PARAMETROS.
  const FormData = {
    company: formControls.company,
    document: encryptData(formControls.document),
    first_name: encryptData(formControls.first_name),
    last_name: encryptData(formControls.last_name),
    birthday: encryptData(formControls.birthday).format('DD-MM-YYYY'),
    email: encryptData(formControls.email),
    cellphone: (formControls.cellphone != null) ? encryptData(formControls.cellphone.toString()) : "",
    password: encryptData(sha256(formControls.password))
  }
  let urlBase = process.env.REACT_APP_INGRESO;
  // EJECUTAR PETICIÓN.
  http.post(`${urlBase}users`, FormData).then(result => { //9
    // VALORES POR DEFECTO.
    let params = { show: true, message: '', typeMessage: 'info' }
    // VALIDAR STATUS - OK.
    if (result.status === 201) {
      // VALIDAR SI LA PETICÓN FUE EXITOSA.
      if (result.data) {
        params.show = false;
        // this.sendCode(result.data);
        this.props.userSignIn(result.data);
        localStorage.setItem('DATAUSER', JSON.stringify(result.data));
        localStorage.setItem(LOGGED_USER_SESSION, JSON.stringify(result.data));
        this.props.history.push(`/securityCode`);
      }
    }
    // VALIDAR STATUS - DOCUMENTO REGISTRADO EN LA APP.
    if (result.status === 452) {
      // MESAJE PARA EL USUARIO.
      params.message = 'El documento ya está registrado en la plataforma.';
      this.setState({
        idMessageIn: params.show,
        MessageIn: params.message,
        typeMessage: params.typeMessage,
        intdisabled: false,
        isLoggedIn: false
      });
    }
    // VALIDAR STATUS - DOCUMENTO REGISTRADO EN LA APP.
    if (result.status === 453) {
      // MESAJE PARA EL USUARIO.
      params.typeMessage = 'error';
      params.message = 'El documento no se encuentra registrado en Jamar y tampoco en CREDIJAMAR';
      this.setState({
        idMessageIn: params.show,
        MessageIn: params.message,
        typeMessage: params.typeMessage,
        intdisabled: false,
        isLoggedIn: false
      });
    }
    // VALIDAR STATUS - DOCUMENTO REGISTRADO EN LA APP.
    if (result.status === 454) {
      // MESAJE PARA EL USUARIO.
      params.typeMessage = 'warning';
      params.message = 'El correo ya se encuentra registrado en la plataforma.';
      this.setState({
        idMessageIn: params.show,
        MessageIn: params.message,
        typeMessage: params.typeMessage,
        intdisabled: false,
        isLoggedIn: false
      });
    }
    // VALIDAR STATUS - DOCUMENTO REGISTRADO EN LA APP.
    if (result.status === 455) {
      // MESAJE PARA EL USUARIO.
      params.typeMessage = 'warning';
      params.message = 'La compañía ingresada es incorrecta.';
      this.setState({
        idMessageIn: params.show,
        MessageIn: params.message,
        typeMessage: params.typeMessage,
        intdisabled: false,
        isLoggedIn: false
      });
    }
    // VALIDAR STATUS - DOCUMENTO REGISTRADO EN LA APP.
    if (result.status === 456) {
      // MESAJE PARA EL USUARIO.
      params.typeMessage = 'warning';
      params.message = 'El número de celular ya se encuentra registrado en la plataforma';
      this.setState({
        idMessageIn: params.show,
        MessageIn: params.message,
        typeMessage: params.typeMessage,
        intdisabled: false,
        isLoggedIn: false
      });
    }
  }).catch((e) => {
    // REASIGNAR MESAGE DE ERROR DE CREDENCIALES.
    this.setState({
      idMessageIn: true,
      MessageIn: 'Lo sentimos, ha ocurrido un error en el sistema.',
      typeMessage: 'error',
      isLoggedIn: false
    });
  })
}

  register = () => {
    this.props.history.push(`/register`);
  }

  render() {
    return (
      <div className="row conten-login">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 envoltura">
          <div className="row">
            <div className="card col-lg-12 col-md-12 col-sm-12 col-12 card-banner-password">
              <div className="row justify-content-center">
                <img
                  src={logoJamar}
                  className="col-lg-2 col-md-2 col-sm-3 col-6 mb-4"
                  alt="Jamar logo"
                />
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 col-sm-7 col-10">
                  <span className="tittle d-block text-center">¡Bienvenido al portal de clientes Jamar!</span>
                  <span className="text d-block text-center">Te invitamos a crear tu contraseña.</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="card col-xl-3 col-lg-4 col-md-6 col-sm-8 col-8 card-form-password">
              <form onSubmit={this.signUp}>
                <div className="col-12">
                  <div className="row mb-2 options">
                    <span className="col-12 text-center">Nueva Contraseña</span>
                  </div>
                </div>
                {/* MESSAGE DE ERROR O CREDENCIALES INVALIDAS */}
                {this.state.idMessageIn ? <div className="form-group"><Alert severity="error">{this.state.MessageIn}</Alert></div> : null}
                {/* INPUT */}
                <div className="form-group">
                  <TextField type="password" className={`col-lg-12 ${this.state.idMessageIn ? 'input_error' : ''}`} name="password" value={this.state.formControls.password} onChange={this.changeHandler} label="Contraseña" required />
                </div>
                <div className="form-group">
                  <TextField type="password" className="col-lg-12" name="confirm_password" value={this.state.formControls.confirm_password} onChange={this.changeHandler} label="Confirmar Contraseña" required />
                </div>
                {/* <div className="cont-buttons"> */}
                <div className="center-log">
                  <div className="content-btn-log mt-3 mb-3">
                    <BtnSubmit title="Continuar" isLoggedIn={this.state.isLoggedIn}></BtnSubmit>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser, SessionData } = auth;
  return { authUser, SessionData }
};

export default connect(mapStateToProps, {
  userSignIn,
  sessionDataId
})(confirmPass);