import React, { useState } from 'react';

const PromoComponent = ({code,stateIsActive, campaing}) => {
    const [step, setStep ] = useState(1);
    return (
        <>
        {
            step == 2 ? (
                <div className="container-iframe">
                    <iframe 
                        src={"https://promos.pagaygana.co/p/" + campaing + "?self_target=1"}
                        frameBorder="0" 
                    />
                    <button className='close-promocion' onClick={() => {stateIsActive(false)}} >X</button>
                </div>
            ) : 
            <div className="container-iframe">
                <div className="indications d-flex justify-content-center align-items-center flex-column">
                    <h4>Tu código es<br/><span className='red'>{code}</span> <br/>copialo antes de avanzar. </h4>

                    <button className='next-click mb-2'  onClick={() => {setStep(2)}}>Ir a la promo</button>
                    <p className="terms">Tus puntos se actualizan cada 24 horas.</p>
                </div>
                <button className='close-promocion' onClick={() => {stateIsActive(false)}} >X</button>
            </div>
        }
        </>
    );
};

export default PromoComponent;