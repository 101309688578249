import React, { Component } from "react";
import "./header.scss";
import logoColombia from "../../assets/images/logo-credijamar.svg";
import botonJamar from "../../assets/images/boton-jamar.svg";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { Icon, Button } from '@material-ui/core';
import { withRouter } from "react-router-dom";

import { userSignOut } from "../../appRedux/actions/Auth";

import axios from 'axios';
import { toast } from 'react-toastify';
class Header extends Component {

  goToSelectModule = () => {
    const { history } = this.props;
    if (history) history.push('/jamarAuth');
  }

  logout = () => {
    this.props.userSignOut();
    const { history } = this.props;
    if (history) history.push('/login');
  }

  goToProfile = () => {
    const { history } = this.props;
    if (history) history.push('/auth/profile');
  }

  goToChat = () => {
    /* if (this.props.userData) {
      let urlToGo = `https://jamar.sagicc.co/canal-chat/getChatWidget?token=adfe2a5e298c8d86ca686968afad694a&type=fullscreen&auto_initialize=true&first_name=${this.props.userData.name}&last_name=${this.props.userData.lastname}&email=${this.props.userData.email}`;
      window.open(urlToGo, "_blank");
    } */
    // 
    if (this.props.userData.id) {
      if (window.DOMINIO_JAMAR_PANAMA.includes(window.location.host)) {
        // 
        const params = {
          sagicc_token: process.env.REACT_APP_TOKEN_SAGICC,
          primer_apellido: this.props.userData.lastname,
          segundo_apellido: '',
          primer_nombre: this.props.userData.name,
          segundo_nombre: '',
          nro_documento: this.props.userData.document,
          tipo_documento: 'CC',
          telefono: this.props.userData.cellphone
        }
        // 
        axios.post('https://jamar.sagicc.co/api/v2/canal-website/website-callback', params).then(result => {
          // 
          if (result.data.status === 200 && result.data.result) {
            // NUMBER.
            //const whatsapp = (this.props.userData.company === 'JA') ? '573222490481' : '507-66785136';
            const whatsapp = '507-66785136';
            // REDIRRECCIÓN A WHATSAPP
            window.open(`https://api.whatsapp.com/send?phone=${whatsapp}&text=Hola,%20me%20puedes%20ayudar?`, '_blank');
          } else {
            // ALERT - CAMPOS REQUERIDOS.
            toast.warn('Lo sentimos, ha ocurrido un error al redirigir al chat. Por favor intente mas tarde.', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          }
        });
      } else {
        if (this.props.userData) {
          console.log(this.props.userData)
          let urlToGo = `https://jamar.sagicc.co/canal-chat/getChatWidget?token=adfe2a5e298c8d86ca686968afad694a&type=fullscreen&auto_initialize=false&first_name=${this.props.userData.name}&last_name=${this.props.userData.lastname}&email=${this.props.userData.email}`;
          //window.open(urlToGo, "_blank");
          window.open(urlToGo);
        }
      }
    }
  }


  styleBtnBack = () => {
    if (this.props.userData && this.props.userData.account) {
      return { visibility: "visible" };
    } else {
      return { visibility: "hidden" };
    }
  }
  render() {
    return (
      <nav className="navbar-credi-jamar">
        {/* <div className="nav-jamar-logo" onClick={() => this.goToSelectModule()}>
          <img
            src={botonJamar}
            alt="Jamar logo"
          />
        </div> */}
        <Button type="button" className="justify-content-start p-0 button-to-credijamar" onClick={() => this.goToSelectModule()} style={this.styleBtnBack()}>
          <img
            src={botonJamar}
            alt="Jamar logo"
          />
        </Button>
        <img
          src={
            this.props.userData && this.props.userData.company === "JP"
              ? logoColombia
              : logoColombia
          }
          className="logo"
          alt="CrediJamar logo"
        />

        <Dropdown className="justify-content-end">
          <Dropdown.Toggle variant="link" size="sm" id="burger-more-options">
            <Icon id="icon-burger-more-options">menu</Icon>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item className="options-burger" onClick={() => this.goToProfile()}><Icon className="icons-options-burger">account_circle</Icon>Perfil</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item className="options-burger" onClick={() => this.logout()}><Icon className="icons-options-burger">exit_to_app</Icon>Cerrar sesión</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </nav>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { userData } = auth;
  return { userData };
};

export default connect(mapStateToProps, { userSignOut })(withRouter(Header));
