import React, { Component } from 'react';
import { connect } from "react-redux";
import './menu.scss';
import {
    userSignIn, sessionDataId
} from "../../appRedux/actions/Auth";
import logoJamar from "../../assets/images/JamarLogo.svg"
import logoCrediJamar from "../../assets/images/logo-credijamar.svg"
class menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formControls: {
                code: ''
            }
        }
    }
    
    render() {
        return (
            <div className="row conten-menu-module">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 envoltura">
                    <div className="row">
                        <div className="card col-lg-12 col-md-12 col-sm-12 col-12 card-banner-module">
                            <div className="row justify-content-center">
                                <img
                                    src={logoJamar}
                                    className="col-lg-2 col-md-2 col-sm-3 col-6"
                                    alt="Jamar logo"
                                />
                            </div>
                            {/* <div className="row justify-content-center publicidad">
                                {
                                    (window.location.host === window.DOMINIO_JAMAR_PANAMA) ?
                                        <img
                                            src={imgPublicidadPanama}
                                            alt="Jamar logo"
                                            height="177"
                                        /> :
                                        <img
                                            src={imgPublicidad}
                                            alt="Jamar logo"
                                            height="177"
                                        />
                                }
                            </div> */}
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="card card-form-module mb-4">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row mb-2 options">
                                        <span className="col-12 text-center">¿A qué portal desea ingresar?</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-around">
                                <div className="col-lg-5 jamar">
                                    <div className="row item-order">
                                        <p className="item-uno">Aquí puedes consultar las compras, entregas y armados.</p>
                                        <div className="col-lg-12 item-dos">
                                            <div className="row justify-content-center content-img" style={{ cursor: "pointer" }} onClick={() => {
                                                this.props.history.push('/jamarAuth');
                                            }}>
                                                <img
                                                    src={logoJamar}
                                                    className="col-lg-6 col-md-6 col-sm-6 col-6"
                                                    alt="Jamar logo"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 credijamar">
                                    <div className="row item-order">
                                        <p className="item-uno">Aquí puedes consultar tus movimientos, cupo y pagar facturas.</p>
                                        <div className="col-lg-12 item-dos">
                                            <div className="row justify-content-center content-img" style={{ cursor: "pointer" }} onClick={() => {
                                                this.props.history.push('/auth');
                                            }}>
                                                <img
                                                    src={logoCrediJamar}
                                                    className="col-lg-6 col-md-6 col-sm-6 col-6"
                                                    alt="Jamar logo"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    const { authUser, SessionData } = auth;
    return { authUser, SessionData }
};

export default connect(mapStateToProps, {
    userSignIn,
    sessionDataId,
})(menu);