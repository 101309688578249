import React from "react";
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import configureStore, { history } from './appRedux/store';
import App from "./containers/App/index";
import AppPagos from "./containers/Pagos/index";

// STYLES
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import './index.scss';
import './fonts.css';
import './globalStyles.css';
// THEMA (color)
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#c52632'
    }
  }
});

const store = configureStore(/* provide initial state if any */);

const NextApp = () =>
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/pagos" component={AppPagos} />
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  </MuiThemeProvider>;

export default NextApp;