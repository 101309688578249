import { PhotoLibrary } from '@material-ui/icons';
import { uploadFile } from 'react-s3';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

export function formatterPeso(value, type = 'JA') {
    // 
    if (type === 'JA') {
        const formatterPeso = new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0
        });
        return formatterPeso.format(value);

    } else {
        const formatterPeso = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        });
        return formatterPeso.format(value);
    }
}

export function capitalize(s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

export async function getCompany() {
    const company = window.DOMINIO_JAMAR_COLOMBIA.includes(window.location.host) ? 'JA' : window.DOMINIO_JAMAR_PANAMA.includes(window.location.host) ? 'JP' : '';
    return company;
}

export function getCookie(name) {
    const cookie = Cookies.get(name);
    return cookie;
}

export function setCookie(name, value) {
    Cookies.set(name, value, { expires: 1,secure: true, sameSite: 'Strict' });
}

export function removeCookie(name) {
    Cookies.remove(name);
}

export function encryptData(dato){
    let secretKey = process.env.REACT_APP_SECRET_KEY;
    let text = dato;
    
    let derived_key = CryptoJS.enc.Base64.parse(secretKey);
    
    let random_number = random16String()
    
    let iv = CryptoJS.enc.Utf8.parse(random_number);
    
    let payload = CryptoJS.AES.encrypt(text, derived_key, { 
        iv : iv, 
        mode : CryptoJS.mode.CBC, 
    }).toString();
    let ipayload =iv+ payload;
    return ipayload;
}

export function decryptData(payload) {    
    let secretKey = process.env.REACT_APP_SECRET_KEY;
    // Derivar la clave
    let derived_key = CryptoJS.enc.Base64.parse(secretKey);
    // El IV codificado y el texto cifrado en el payload están concatenados
    let iv_base64 = payload.split('=')[0] + "==";  // Asumimos que el IV termina en '==' en base64
    let ciphertext_base64 = payload.substring(iv_base64.length);

    // Decodificar IV y texto cifrado desde Base64
    let iv = CryptoJS.enc.Base64.parse(iv_base64);
    let ciphertext = CryptoJS.enc.Base64.parse(ciphertext_base64);

    // Desencriptar el texto
    let decrypted = CryptoJS.AES.decrypt(
    { ciphertext: ciphertext },
    derived_key,
    { iv: iv, mode: CryptoJS.mode.CBC }
    );
    // Convertir de UTF-8 y mostrar el resultado
    let plaintext = decrypted.toString(CryptoJS.enc.Utf8);
    return plaintext;
}

function  random16String ( ) { 
    let result = '' ; 
    for ( let i = 0 ; i < 16 ; i++) { 
        result += Math.floor(Math.random () * 10); 
    } 
    return  String (result); 
};

export function convertKeysToLowercase(arr) {
    return arr.map(obj => {
        let newObj = {};
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                newObj[key.toLowerCase()] = obj[key];
            }
        }
        return newObj;
    });
};

export function processArrayAddress(array, skipKey) {
    return array.map(item => {
        const newItem = {};
        const keys = Object.keys(item);

        keys.forEach(key => {
            if (item[key] === null) {
                newItem[key] = item[key];
            } else if (key === skipKey) {
                newItem[key] = item[key];
            } else {
                try {
                    newItem[key] = decryptData(item[key]);
                } catch (error) {
                    newItem[key] = item[key]; // En caso de error, usa el valor original
                }
            }
        });

        return newItem;
    });
}
