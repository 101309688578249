import {
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNIN_DATA_USER,
  SIGNIN_DATA_USER_SUCCESS,
  USER_UPDATE,
  SESSION_DATA,
  SESSION_DATA_SUCCESS
} from "../../constants/ActionTypes";

export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user
  };
};

export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};

export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};

export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser
  };
};

export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  }
};

export const userDataInSuccess = (userData) => {
  return {
    type: SIGNIN_DATA_USER_SUCCESS,
    payload: userData
  }
}

export const userDataUpSuccess = (userData) => {
  return {
    type: SIGNIN_DATA_USER,
    payload: userData
  }
}

export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const userUpdate = (user) => {
  return {
    type: USER_UPDATE,
    payload: user
  };
};

export const sessionDataId = (session) => {
  return {
    type: SESSION_DATA,
    payload: session
  };
};

export const sessionDataSuccess = (session) => {
  return {
    type: SESSION_DATA_SUCCESS,
    payload: session
  };
};
