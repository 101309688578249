import React, { useEffect } from "react";
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 220,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    padding: "0 70px 20px 30px",
    textAlign: "left",
  },
  select: {
    marginBottom: 10,
  },
  condicionesPanama: {
    marginTop: 20,
    lineHeight: 2,
    position: "relative",
    right: 23
  },
}));

const regex = /[^0-9]/;
const regexPanama = /[^0-9A-Z-]/;

const isFromPanama = () => {
  return window.DOMINIO_JAMAR_PANAMA.includes(window.location.host);
};

const DocumentType = ({
  documentType,
  setDocumentType,
  documentNumber,
  setDocumentNumber,
  setShouldShowButton,
}) => {
  const classes = useStyles();

  useEffect(() => {
    setShouldShowButton(documentNumber ? true : false);
  }, [documentNumber, setShouldShowButton]);

  return (
    <>
      <div className={classes.formContainer}>
        <FormControl className={classes.formControl}>
          <InputLabel id="document-type-select">Tipo de documento</InputLabel>
          <Select
            labelId="document-type-select"
            id="document-type-select"
            className={classes.select}
            value={documentType}
            onChange={(event) => setDocumentType(event.target.value)}
            MenuProps={{
              classes: {
                paper: classes.dropdownStyle,
              },
            }}
          >
            <MenuItem value={"cedula"}>Cédula de ciudadanía</MenuItem>
            {isFromPanama() && (
              <MenuItem value={"pasaporte"}>Pasaporte</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Número del documento"
            value={documentNumber}
            onChange={(event) =>
              setDocumentNumber(
                event.target.value.replace(
                  isFromPanama() ? regexPanama : regex,
                  ""
                )
              )
            }
            required
          />
        </FormControl>
        {isFromPanama() && (
          <ul className={classes.condicionesPanama}>
            <li>
              Si tu documento de identidad empieza con cero (0){" "}
              <strong>no</strong> incluirlo.
            </li>
            <li>
              Si tu documento de identidad incluye guiones (-){" "}
              <strong>debes</strong> ingresarlos.
            </li>
            <li>
              Si tu documento de identidad incluye letras <strong>debes</strong>{" "}
              incluirlas.
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default DocumentType;
