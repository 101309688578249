import React from 'react';

import santaIcon from './images/icon-santipuntos-render.svg'; 


const RenderSantasIcon = ({ santipuntos }) => {
  let totalPuntos = santipuntos;
  let maximosPuntos = 7;

  const santas = Array.from({ length: maximosPuntos }, (_, index) => (
    <div 
    className={`icon-puntos d-flex flex-column justify-content-center align-items-center align-content-center 
      ${totalPuntos > index ? 'active' : 'opacity'}`
    }>
      <img src={santaIcon} alt="" />
      <span className='main-text-icon'>
        {index+1}
      </span>
      <span>
        Puntos
      </span>
    </div>
  ));

  return <div className='d-flex justify-content-center'>{santas}</div>;
};

export default RenderSantasIcon;