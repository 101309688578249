import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "./recoverPassword.scss";
import logoJamar from "../../assets/images/JamarLogo.svg";
import { connect } from "react-redux";
import DocumentType from "./steps/DocumentType";
import ReceptionSelect from "./steps/ReceptionSelect";
import CardContainer from "../../components/CardContainer";
import InsertCode from "./steps/InsertCode";
import NewPassword from "./steps/NewPassword";
import Success from "./steps/Success";
import loadingCircle from "../../assets/images/loading-circle-red.gif";

const RecoverPassword = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowButton, setShouldShowButton] = useState(false);
  const [step, setStep] = useState(1);
  const [documentType, setDocumentType] = useState("cedula");
  const [documentNumber, setDocumentNumber] = useState("");
  const [reception, setReception] = useState("email");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changePasswordToken, setChangePasswordToken] = useState(null);

  const _LOADING = (
    <div className="mt-2 col-12 text-center">
      <img className="mt-1" src={loadingCircle} alt="loadingCircle" />
    </div>
  );

  const goBack = () => {
    props.history.push("/login");
  };

  const handleSetStep = (action = "forwards") => {
    if (step === 5 && action === "forwards") {
      goBack();
    } else if (action === "forwards") {
      setIsLoading(true);
      setShouldShowButton(false);
      setStep((currentStep) => currentStep + 1);
    } else if (step > 1) {
      setStep((currentStep) => currentStep - 1);
    } else {
      goBack();
    }
  };

  const STEPS = {
    1: {
      component: (
        <DocumentType
          setDocumentType={setDocumentType}
          documentType={documentType}
          setDocumentNumber={setDocumentNumber}
          documentNumber={documentNumber}
          setShouldShowButton={setShouldShowButton}
        />
      ),
      title: "OLVIDÉ MI CONTRASEÑA",
    },
    2: {
      component: (
        <ReceptionSelect
          reception={reception}
          setReception={setReception}
          setShouldShowButton={setShouldShowButton}
          documentNumber={documentNumber}
          loadingIcon={_LOADING}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          goBack={goBack}
        />
      ),
      title: "OLVIDÉ MI CONTRASEÑA",
    },
    3: {
      component: (
        <InsertCode
          documentNumber={documentNumber}
          reception={reception}
          loadingIcon={_LOADING}
          setShouldShowButton={setShouldShowButton}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleSetStep={handleSetStep}
          setChangePasswordToken={setChangePasswordToken}
        />
      ),
      title: "OLVIDÉ MI CONTRASEÑA",
    },
    4: {
      component: (
        <NewPassword
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          setShouldShowButton={setShouldShowButton}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          loadingIcon={_LOADING}
          changePasswordToken={changePasswordToken}
          handleSetStep={handleSetStep}
        />
      ),
      title: "NUEVA CONTRASEÑA",
    },
    5: {
      component: (
        <Success
          setIsLoading={setIsLoading}
          setShouldShowButton={setShouldShowButton}
        />
      ),
      title: "FELICIDADES",
    },
  };

  return (
    <>
      <div className="red-banner"></div>
      <div className="main-container">
        <div className="jamar-logo">
          <img src={logoJamar} alt="logo jamar" />
          <div className="welcome">
            <p className="title">¡Bienvenido al portal de clientes!</p>
            <p className="description">
              Completa los pasos a continuación para recuperar tu contraseña
            </p>
          </div>

          <CardContainer
            cardTitle={STEPS[step].title}
            centerTitle
            separationLine
            button
            showButton={shouldShowButton}
            buttonFunction={() => handleSetStep()}
            buttonText="Continuar"
            isRed
            noBorder
            goBackButton={step < 4}
            goBackFunction={() => handleSetStep("backwards")}
          >
            {STEPS[step].component}
          </CardContainer>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ settings }) => {
  const { heightGlobal } = settings;
  return { heightGlobal };
};

export default connect(mapStateToProps, {})(withRouter(RecoverPassword));
