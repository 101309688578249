import React from 'react';
import { connect, useSelector } from 'react-redux';
import './scss/BannerRecurrencyComp.scss';


const RecurrencyComponent = (props) => {
    let loadingComponenteState = useSelector(state => state.recurrency)
    return (
        <>
        { loadingComponenteState.showRecurrency == true ?
            <div className="centrar">
                <div className="container-banner-recurrency max-960 card-content">
                   <div className="container-interaction-recurrency">
                    <h4 className="banner-recurrency-title"> Hola {props.auth.userData.name}, Ahora tu crédito se paga solo, con los pagos recurrentes de Jamar.</h4>
                        <p className="banner-information">Actívalo con unos simples pasos, ¡qué esperas!</p>
                        <a href='/auth/recurrency' className="enable-recurrency"> ¡Activar recurrencia!</a>
                   </div>
   
                    <img className="banner-img-recurrency" src="https://cdn.shopify.com/s/files/1/0516/5478/7271/files/formulario-tu-opinion_02.jpg?v=1681475090" alt='Banner de recurrencia' />
                </div>
            </div>
            : loadingComponenteState.showRecurrency == 'loading' ?
            <div className="centrar">
                <div className="container-banner-recurrency max-960 card-content">
                   <div className="loading-content-portal">
                        <div></div>
                        <p>Estamos cargando el estado de su suscripción</p>
                    </div>
                </div>
            </div>
            : 
            <div className="centrar">
                <div className="container-banner-recurrency max-960 card-content">
                   <div className="container-interaction-recurrency">
                    <h4 className="banner-recurrency-title"> Hola {props.auth.userData.name}, ya has activado los pagos automáticos.</h4>
                        <p className="banner-information">Cualquier consulta o duda no dudes en ponerte en contacto leer las preguntas frecuentes.</p>
                        <a href='/auth/recurrency' className="enable-recurrency"> Ver más</a>
                   </div>
   
                    <img className="banner-img-recurrency" src="https://cdn.shopify.com/s/files/1/0516/5478/7271/files/formulario-tu-opinion-confirmacion-PC_02.jpg?v=1681475007" alt='Banner de recurrencia' />
                </div>
            </div>
        }
        </>
    )
}



const mapStateToProps = state => state;

export default connect(mapStateToProps, {})(RecurrencyComponent);
