import { useState, useEffect } from 'react';

const getHeight = () => window.innerHeight;

function useCurrentHeight() {
    let [Height, setHeight] = useState(getHeight());

    useEffect(() => {
        if (window.location.pathname !== '/auth/recurrency'){
            let timeoutId = null;
            const resizeListener = () => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => setHeight(getHeight()), 150);
            };
            window.addEventListener('resize', resizeListener);
    
            return () => {
                window.removeEventListener('resize', resizeListener);
            }
        }
    }, [])

    return Height;
}

export default useCurrentHeight;