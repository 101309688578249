import React from "react";
// import { useHistory } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
// 
import HeaderBar from '../Sidebar/Header';
import Menu from '../Sidebar/Menu';
import Header from "../Header";
// VISTAS - ROUTER.
import Views from '../../pages/views/index';
import './MainApp.scss';

require('dotenv').config();

const MainApp = ({ heightContent }) => {
    // 
    const match = useRouteMatch();

    return (
        <div className="row justify-content-center half-background" style={{ width: "100%" }}>
            <div id="content-header" className="row justify-content-center" style={{ width: "100%" }}> <Header /> </div>
            <div className="root-container">
                <Menu />
                <HeaderBar />
                <Views match={match} heightContent={heightContent} />
            </div>
        </div>
    )
}

export default MainApp;