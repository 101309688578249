import React, { Suspense, lazy } from 'react';
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { CircularProgress } from '@material-ui/core';
import './pay/pay.scss';
import Warranty from './warranty/warranty';

const ViewsJamar = () => {
    // 
    const match = useRouteMatch();
    // 
    console.log(match.url)
    // debo instalar los modpay
    return (
        <div className="content-view-router pay-styles"  >
            <Suspense fallback={<CircularProgress />}>
                <Switch>
                    <Route exact path={`${match.url}/purchases`} component={lazy(() => import('../viewsJamar/productos/productos'))} />
                    <Route exact path={`${match.url}/delivery`} component={lazy(() => import('./delivery/delivery'))} />
                    <Route exact path={`${match.url}/assembly`} component={lazy(() => import('./assembly/assembly'))} />
                    <Route exact path={`${match.url}/warrantyCases`} component={lazy(() => import('./warrantyCases/warrantyCases'))} />
                    <Route exact path={`${match.url}/warranty`} component={Warranty} />
                    <Route exact path={`${match.url}/profile`} component={lazy(() => import('./profile/profile'))} />
                    <Route exact path={`${match.url}`} component={Warranty} />
                </Switch>
            </Suspense>
        </div>
    );
};

export default ViewsJamar;