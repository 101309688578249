import {
    PURCHASES_DATA,
    PURCHASES_DATA_SUCCESS,
    WARRANTY_CASES_DATA_SUCCESS,
    ON_SHOW_LOADER,
    ON_HIDE_LOADER,
    ON_SHOW_SUBLOADER,
    ON_HIDE_SUBLOADER,
    EXPANDED_PURCHASE_SUCCESS,
    DELIVERY_QUALIFICATION,
    ASSEMBLY_QUALIFICATION,
    PRODUCT_QUALIFICATION,
    DELIVERY_QUALIFICATION_RESULT,
    ASSEMBLY_QUALIFICATION_RESULT,
    PRODUCT_QUALIFICATION_RESULT,
    GET_PURCHASE_PRODUCTS,
    GET_WARRANTY_CASES
} from "../../constants/ActionTypes";

export const setpurchasesData = (userData) => {
    return {
        type: PURCHASES_DATA,
        payload: userData
    };
};

export const purchasesDataSuccess = (purchases) => {
    return {
        type: PURCHASES_DATA_SUCCESS,
        payload: purchases
    };
};

export const warrantyDataSuccess = (warrantyCases) => {
    return {
        type: WARRANTY_CASES_DATA_SUCCESS,
        payload: warrantyCases
    };
};

export const showLoader = () => {
    return {
        type: ON_SHOW_LOADER
    };
};

export const hideLoader = () => {
    return {
        type: ON_HIDE_LOADER,
    };
};

export const showSubLoader = () => {
    return {
        type: ON_SHOW_SUBLOADER
    };
};

export const hideSubLoader = () => {
    return {
        type: ON_HIDE_SUBLOADER,
    };
};

export const expandedPurchaseSuccess = (purchase) => {
    return {
        type: EXPANDED_PURCHASE_SUCCESS,
        payload: purchase
    };
};

export const deliveryQualification = (params) => {
    return {
        type: DELIVERY_QUALIFICATION,
        payload: params
    };
};

export const deliveryQualificationResult = (params) => {
    return {
        type: DELIVERY_QUALIFICATION_RESULT,
        payload: params
    };
};

export const assemblyQualification = (params) => {
    return {
        type: ASSEMBLY_QUALIFICATION,
        payload: params
    };
};

export const assemblyQualificationResult = (params) => {
    return {
        type: ASSEMBLY_QUALIFICATION_RESULT,
        payload: params
    };
};

export const productQualification = (params) => {
    return {
        type: PRODUCT_QUALIFICATION,
        payload: params
    };
};

export const productQualificationResult = (params) => {
    return {
        type: PRODUCT_QUALIFICATION_RESULT,
        payload: params
    };
};

export const getPurchaseProducts = (params) => {
    return {
        type: GET_PURCHASE_PRODUCTS,
        payload: params
    };
};

export const getWarrantyCases = (params) => {
    return {
        type: GET_WARRANTY_CASES,
        payload: params
    };
};